import React from "react";
import { AppBackgrounds } from "../assets/background/background";

const JobCardSeeker = (props) => {
  const { job, onClick } = props;

  return (
    <div className="job-card my-2" onClick={onClick}>
      <div className="job-img">
        <div style={{ objectFit: "cover" }}>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/jobs%2F${job.id}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`}
            alt={`${job.title}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = AppBackgrounds.bg_1;
            }}
          />
        </div>
      </div>
      <div className="job-card-body">
        <p className="mb-2 text-muted" style={{ fontSize: "12px" }}>
          {job.recruiterName}
        </p>
        <h3>{job.title}</h3>
        <p className="d-flex justify-content-between">
          {job.slpStudents ? (
            <span>Case Load : {job.slpStudents}</span>
          ) : (
            <span>{job.setting}</span>
          )}
          <span>Platform: {job.telepathyPlatform}</span>
        </p>
        <p className="d-flex justify-content-between">
          <span>Applicants : {Object.values(job?.applicants)?.length}</span>
          <span
            className={
              job.status === "active" ? "jb bg-warning" : "jb bg-success"
            }
          >
            {job.status}
          </span>
        </p>
        <p className="text-muted">
          {job.populationServed?.join(", ")}
        </p>
      </div>
    </div>
  );
};

// Exporting the memoized component
export default React.memo(JobCardSeeker);
