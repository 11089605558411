import React, { useState, useEffect, useRef } from "react";
import { Spinner } from 'react-bootstrap';
import avatarHolder from '../assets/avatarHolder.png';
import gradientBanner from '../assets/gradientBanner.png';

import {
  Autocomplete,
  InputLabel,
  IconButton,
  TextField,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { hasError } from "../utils/utils";
import { FirebaseAuthService, ProfileService } from "../services";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import { AppBackgrounds } from "../assets/background/background";
import { DashIcons } from "../assets/icons/dashIcons";
import Swal from "sweetalert2";
import { auth } from "../firebase-config";
import { useSelector } from "react-redux";
const classifications = ["SLP", "SLPA", "SLP - CF", "Virtual Assistant",
"Paraprofessional"];

const MyProfilePage = () => {
  const [isSaving, setIsSaving] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const isCompany = useSelector((state) => state.authReducer.isCompany);
  const [isMyProfile, setIsMyProfile] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [userId, setUserId] = useState("");
  const [profileData, setData] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    avatar: "",
    bio: "",
    place: "",
    dob: "",
    fb: "",
    insta: "",
    blog: "",
    yt: "",
    twitter: "",
    linkedin: "",
    coverPhoto: "",
    role: "",
    active: "",
  });
  const [error, setError] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    avatar: "",
    bio: "",
    place: "",
    dob: "",
    notFound: false,
  });
  const [locSuggestions, setLocSuggestions] = useState([]);
  const getLocation = async (value) => {
    if (value.length > 4) {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${value}&countrycodes=US`
        );
        setLocSuggestions(response.data);
      } catch (error) {}
    }
  };
  const formatAddress = (address) => {
    let addressParts = address.split(",");
    addressParts.forEach((x, i) => {
      if (!isNaN(x)) {
        addressParts.splice(i, 1);
      }
    });
    const lastThreeItems = addressParts.slice(-3);
    return lastThreeItems.join(", ");
  };

  const isValid = () => {
    let flag = true;
    let errorData = {};
    if (profileData.name === "") {
      flag = false;
      errorData.name = "Full Name cannot be blank";
    }
    if (profileData.username === "") {
      flag = false;
      errorData.username = "Username cannot be blank";
    }
    if (profileData.bio === "") {
      flag = false;
      errorData.bio = "Bio cannot be blank";
    }
    // if (profileData.phone === "") {
    //   flag = false;
    //   errorData.phone = "Phone Number cannot be blank";
    // }
    setError(errorData);
    return flag;
  };

  const getProfile = async (id) => {
    if (!id) return; // Ensure we have a valid id before calling
    try {
      const res = await ProfileService.getProfileDetails(id);
      if (res.exists) {
        const data = res.data();
        console.log("Profile Data:", data); // Log the data to verify
        setData({
          name: data.name || "",
          username: data.username || "",
          email: data.email || "",
          phone: data.phone || "",
          avatar: "",
          bio: data.bio || "",
          place: data.place || "",
          dob: data.dob || "",
          fb: data.fb || "",
          insta: data.insta || "",
          blog: data.blog || "",
          yt: data.yt || "",
          twitter: data.twitter || "",
          linkedin: data.linkedin || "",
          coverPhoto: "",
          role: data.role || "",
          active: data.active || "",
        });
        setError({ ...error, notFound: false });
      } else {
        Swal.fire({ text: "Unable to get profile details" });
        setError({ ...error, notFound: true });
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      Swal.fire({ title: "Error", text: error.message });
    }
  };
  
  

  const saveProfile = async () => {
    if (isValid()) {
        setIsSaving(true); 
        try {
            await ProfileService.updateProfileDetails(userId, profileData);
            Swal.fire("Profile Updated!");
            setEditMode(false);

            // If the avatar is updated, store the new image
            if (profileData.avatar) {
                await ProfileService.storeProfileImage(profileData.avatar.split("base64,")[1], userId, "avatar");
            }

            // Trigger a global event to refresh the avatar in the sidebar
            window.dispatchEvent(new Event("profileUpdated"));
        } catch (error) {
            Swal.fire(error.message);
        } finally {
            setIsSaving(false);
        }
    }
};

  
  
  

  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result; // base64 string
        if (type === "cover") {
          setData({ ...profileData, coverPhoto: result });
        } else {
          setData({ ...profileData, avatar: result }); // Set the avatar as base64 for immediate display
        }
      };
      reader.readAsDataURL(file);
    }
  };
  

  useEffect(() => {
    let userIdVar;
  
    if (location.pathname.startsWith("/profiles/")) {
      userIdVar = location.pathname.split("/").pop();
      setIsMyProfile(false);
      getProfile(userIdVar);
    } else {
      const fetchUser = async () => {
        // Wait until the currentUser is available
        while (!auth.currentUser) {
          await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 100ms
        }
        userIdVar = auth.currentUser.uid;
        setUserId(userIdVar);
        getProfile(userIdVar);
      };
  
      fetchUser();
    }
  }, []);
  
  

  const uploadCover = useRef(null);
  const uploadDp = useRef(null);
  const copyLink = () => {
    const url = `${window.location.origin}/profile/${userId}`;
    const inputElement = document.createElement("input");
    inputElement.value = url;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand("copy");
    document.body.removeChild(inputElement);
    Swal.fire("URL copied to clipboard: " + url);
  };

  return (
    <>
      {!error.notFound && (
        <Container className="profile-page-container">
          <Row className="justify-content-center">
            <Col md={12} className="p-0">
              <div className="profile-cover-bg">
                <img
                  src={
                    profileData.coverPhoto
                      ? profileData.coverPhoto
                      : `https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/profile%2Fcover%2F${userId}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`
                  }
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = gradientBanner; // This will use your local avatar placeholder
                  }}
                />
                {editMode && (
                  <span className="edit-cover-btn">
                    <IconButton
                      color="primary"
                      onClick={() => uploadCover.current.click()}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col md={6}>
              <div className="row profile-cover">
                <div className="col-3 avatar-container p-0">
                <img
  src={
    profileData.avatar && profileData.avatar.startsWith("data:image/")
      ? profileData.avatar // Base64 preview for newly uploaded images
      : `https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/profile%2Favatar%2F${userId}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`
  }
  alt="User Avatar"
  onError={({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = avatarHolder; // This will use your local avatar placeholder
  }}
  style={{ width: "150px", height: "150px", objectFit: "cover" }} // Adjust the style as per your requirements
/>


                  
                  {editMode && (
                    <span className="edit-dp-btn">
                      <IconButton
                        color="primary"
                        onClick={() => uploadDp.current.click()}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  )}
                </div>
                <div className="col-9 profile-cover-body">
                  <h3>
                    {profileData.name}
                    {isMyProfile && (
                      <IconButton
                        color="primary"
                        onClick={() => setEditMode(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton color="primary" onClick={copyLink}>
                      <ShareIcon />
                    </IconButton>
                  </h3>
                  <h6>@{profileData.username}</h6>
                  <p>{profileData.place} | Solo Practitioner</p>
                  {/* {profileData.username && <h6>@{profileData.username}</h6>}
                  {profileData.place && (
                    <p>{profileData.place} | Solo Practitioner</p>
                  )} */}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <h3>Profile Details</h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={3}>
              <TextField
                error={hasError(error.name)}
                label="Full Name"
                variant="standard"
                placeholder="Enter Full Name"
                fullWidth
                value={profileData.name}
                helperText={error.name}
                onChange={(e) =>
                  setData({ ...profileData, name: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
            <Col md={3}>
              <TextField
                error={hasError(error.username)}
                label="Username"
                variant="standard"
                placeholder="Enter Username"
                fullWidth
                helperText={error.username}
                value={profileData.username}
                onChange={(e) =>
                  setData({ ...profileData, username: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center my-3">
            <Col md={3}>
              <TextField
                placeholder="Enter Phone Number"
                label="Phone"
                variant="standard"
                fullWidth
                helperText={error.phone}
                error={hasError(error.phone)}
                value={profileData.phone}
                onChange={(e) =>
                  setData({ ...profileData, phone: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
            <Col md={3}>
              <TextField
                label="Email"
                InputProps={{
                  readOnly: true,
                }}
                focused
                variant="standard"
                fullWidth
                className="d-block"
                value={profileData.email}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col md={{ span: 6, offset: 3 }}>
              {editMode ? (
                <Autocomplete
                  disablePortal
                  variant="standard"
                  options={locSuggestions}
                  getOptionLabel={(e) => (e ? e.display_name : "")}
                  onChange={(e, newValue) => {
                    setData({
                      ...profileData,
                      place: formatAddress(newValue.display_name),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      variant="standard"
                      color="primary"
                      label="Location"
                      value={profileData.place}
                      fullWidth
                      onBlur={() => {
                        setLocSuggestions([]);
                      }}
                      onChange={(e) => {
                        getLocation(e.target.value);
                      }}
                    />
                  )}
                  inputProps={{
                    readOnly: !editMode,
                  }}
                />
              ) : (
                <TextField
                  label="Location"
                  variant="standard"
                  fullWidth
                  value={profileData.place}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              )}
            </Col>
          </Row>
          <Row className="justify-content-center my-2">
            <Col md={6}>
              <h3>Professional Details</h3>
            </Col>
          </Row>
          <Row className="justify-content-center my-3">
            <Col md={{ span: 6 }}>
              <TextField
                label="Bio"
                placeholder="Write about yourself and your service.."
                multiline
                rows={2}
                variant="filled"
                helperText={error.bio}
                error={hasError(error.bio)}
                value={profileData.bio}
                fullWidth
                onChange={(e) =>
                  setData({ ...profileData, bio: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center my-3">
            <Col md={{ span: 3 }}>
              <FormControl fullWidth>
                <InputLabel>Job Role</InputLabel>
                <Select
                  value={profileData?.role}
                  label="Job Role"
                  onChange={(e) =>
                    setData({ ...profileData, role: e.target.value })
                  }
                  inputProps={{
                    readOnly: !editMode,
                  }}
                >
                  <MenuItem key="blank" value="">
                    -- Select --
                  </MenuItem>
                  {classifications.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col md={{ span: 3 }}>
              <FormControl fullWidth>
                <InputLabel>Actively Looking</InputLabel>
                <Select
                  value={profileData?.active}
                  label="Company Size"
                  onChange={(e) =>
                    setData({ ...profileData, active: e.target.value })
                  }
                  inputProps={{
                    readOnly: !editMode,
                  }}
                >
                  <MenuItem key="1" value="YES">
                    YES
                  </MenuItem>
                  <MenuItem key="2" value="NO">
                    NO
                  </MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>
          {editMode && (
            <>
              <Row className="justify-content-center my-3">
                <Col md={6}>
                  <h3>Social Links</h3>
                </Col>
              </Row>

              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Facebook Link"
                    label="Facebook"
                    variant="standard"
                    fullWidth
                    value={profileData.fb}
                    onChange={(e) =>
                      setData({ ...profileData, fb: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Linkedin Link"
                    label="Linkedin"
                    variant="standard"
                    fullWidth
                    value={profileData.linkedin}
                    onChange={(e) =>
                      setData({ ...profileData, linkedin: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Blog Link"
                    label="Blog"
                    variant="standard"
                    fullWidth
                    value={profileData.blog}
                    onChange={(e) =>
                      setData({ ...profileData, blog: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Instagram Link"
                    label="Instagram"
                    variant="standard"
                    fullWidth
                    value={profileData.insta}
                    onChange={(e) =>
                      setData({ ...profileData, insta: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Youtube Link"
                    label="Youtube"
                    variant="standard"
                    fullWidth
                    value={profileData.yt}
                    onChange={(e) =>
                      setData({ ...profileData, yt: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Twitter Link"
                    label="Twitter"
                    variant="standard"
                    fullWidth
                    value={profileData.twitter}
                    onChange={(e) =>
                      setData({ ...profileData, twitter: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {!editMode && (
            <>
              {(profileData.fb ||
                profileData.insta ||
                profileData.blog ||
                profileData.linkedin ||
                profileData.twitter ||
                profileData.yt) && (
                <Row className="justify-content-center my-3">
                  <Col md={6}>
                    <h3>Social Links</h3>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-center my-2">
                <Col md={6}>
                  <div className="social-links-container">
                    {profileData.fb && (
                      <span className="social-links">
                        <a href={profileData.fb} target="_blank">
                          <img src={DashIcons.socialMedia.fb}></img>
                        </a>
                      </span>
                    )}
                    {profileData.linkedin && (
                      <span className="social-links">
                        <a href={profileData.linkedin} target="_blank">
                          <img src={DashIcons.socialMedia.linkedin}></img>
                        </a>
                      </span>
                    )}
                    {profileData.twitter && (
                      <span className="social-links">
                        <a href={profileData.twitter} target="_blank">
                          <img src={DashIcons.socialMedia.twitter}></img>
                        </a>
                      </span>
                    )}
                    {profileData.insta && (
                      <span className="social-links">
                        <a href={profileData.insta} target="_blank">
                          <img src={DashIcons.socialMedia.insta}></img>
                        </a>
                      </span>
                    )}
                    {profileData.yt && (
                      <span className="social-links">
                        <a href={profileData.yt} target="_blank">
                          <img src={DashIcons.socialMedia.yt}></img>
                        </a>
                      </span>
                    )}
                    {profileData.blog && (
                      <span className="social-links">
                        <a href={profileData.blog} target="_blank">
                          <img src={DashIcons.socialMedia.blog}></img>
                        </a>
                      </span>
                    )}
                  </div>
                
                </Col>
              </Row>
            </>
          )}
          {editMode && (
            <Row className="justify-content-center my-3">
              <Col md={3} className="d-grid">
              <Button onClick={saveProfile} disabled={isSaving}>
      {isSaving ? (
        <>
          <Spinner animation="border" size="sm" className="me-2" />
          Saving...
        </>
      ) : (
        "Save"
      )}
    </Button>
              </Col>
              <Col md={3} className="d-grid">
                <Button
                  onClick={() => setEditMode(false)}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
          <input
            type="file"
            hidden
            name="dp"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, "avatar")}
            ref={uploadDp}
          />
          <input
            type="file"
            hidden
            name="cover"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, "cover")}
            ref={uploadCover}
          />
        </Container>
      )}
      {error.notFound && <h3 className="text-center my-5">Not Found</h3>}
      <div className="pb-5 mb-5"></div>
    </>
  );
};

export default MyProfilePage;