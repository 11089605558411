import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedin: false,
    user: {
      avatar: "", // Added avatar to the user object
    },
    auth: {},
    isCompany: false,
  },
  reducers: {
    setLogin: (state, action) => {
      state.isLoggedin = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setCompany: (state, action) => {
      state.isCompany = action.payload;
    },
    updateAvatar: (state, action) => {
      state.user.avatar = action.payload; // Update the avatar in the user object
    },
  },
});

export const { setLogin, setAuth, setUser, setCompany, updateAvatar } = authSlice.actions;

export default authSlice.reducer;
