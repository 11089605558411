import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { FirebaseAuthService, JobService } from "../../services";
import Swal from "sweetalert2";
import statesAndCities from '../../services/statesAndCities.json'; // Assuming your JSON is correctly formatted

const caseLoadOptions = [
  "1 - 10",
  "10 - 20",
  "20 - 30",
  "30 - 40",
  "40 - 50",
  "50 - 60",
  "60 - 70",
  "70 - 80",
  "80 - 90",
  "90 - 100",
  "100 or more",
];

const caseloadMgmtOptions = [
  "N/A",
  "1 - 10",
  "10 - 20",
  "20 - 30",
  "40 - 50",
  "50 - 60",
  "60 - 70",
  "70 - 80",
];

const slpStudentsOptions = [
  "N/A",
  "1 - 20",
  "20 - 40",
  "40 - 60",
  "60 - 80",
  "80 - 100",
];
const annualSalaryRange = [
  "40 - 50",
  "50 - 60",
  "60 - 70",
  "70 - 80",
  "80 - 90",
  "90 - 100",
];

const companySizes = [
  "1 - 10",
  "11 - 50",
  "51 - 200",
  "201 - 500",
  "501 - 1000",
  "1000+",
];

const directTherapyStudents = ["1", "2", "3", "4 or more"];

const sitesToCoverOptions = ["1", "2", "3 or more"];

const salaryRangeOptions = [
  "20 - 30",
  "30 - 40",
  "40 - 50",
  "50 - 60",
  "60 - 70",
  "70 - 80",
  "80 or more",
];
const jobtype = ["Hybrid", "Remote", "In-Person"];

const settings = [
  "School",
  "Clinic / Oupatient",
  "Home Health",
  "Hospital",
  "Acute Rehab",
  "Longterm Rehab",
  "University / Academic",
  "Non-Clinical",
  // "Alternatives",
];
const classifications = [
  "SLP",
  "SLPA",
  "SLP - CF",
  "SLP Supervisor",
  "Virtual Assistant",
  "Paraprofessional",
];

const jobHours = ["Part-Time", "Full-Time", "PRN"];

const CreateJob = () => {
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.authReducer.user);
  const [selectedState, setSelectedState] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [formData, setFormData] = useState({
    caseloadMgmt: "",
    slpStudents: "",
    directTherapyStudents: "",
    pslf: "",
    pslfDetails: "",
    benefits: "",
    benefitDetails: "",
    isDirectHire: "",
    telepathyPlatform: "",
    populationServed: [],
    salaryRange: "",
    isIndirectTimePaid: "",
    inDirectTimePaidDetails: "",
    sitesToCover: "",
    location: "",
    status: "active",
    title: "",
    type: "",
    setting: "",
    classification: "",
    companySize: "",
    jobHours: "",
    state: "",
    city: "",
  });

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    if (selectedState) {
      setCityOptions(statesAndCities[selectedState]);
      setFormData({ ...formData, city: '' });
    }
  }, [selectedState]);

  const handleSubmit = async (event) => {
    const user = FirebaseAuthService.getUserId();
    event.preventDefault();
    try {
      const res = await JobService.createJob({
        ...formData,
        recruiter: user,
        company: userProfile.name,
        applicants: {},
      });
      if (formData.imageUrl) {
        JobService.storeJobsImage(formData.imageUrl, res.id);
      }
      
      Swal.fire({ text: "Job Created Successfully" });
    
      navigate("/manage-jobs");
    } catch (error) {
      console.log(error);
      Swal.fire({ text: "Error while creating Job" });
    }
  };

  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result; // base64 string
        setFormData({ ...formData, [type]: result });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form
      className="my-5"
      onSubmit={handleSubmit}
      style={{ width: "60%", margin: "auto" }}
    >
      <div
        style={{
          borderRadius: "15px",
          boxShadow: "5px 5px 20px #0003",
          padding: "20px",
          margin: "40px",
          backgroundColor: "#f6f6f6",
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          {/* Job Title */}
          <Grid item xs={12}>
            <TextField
              label="Job Title"
              variant="outlined"
              fullWidth
              onChange={(e) => handleChange("title", e.target.value)}
              value={formData?.title}
              required
            />
          </Grid>

          {/* Job Description */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              onChange={(e) => handleChange("description", e.target.value)}
              value={formData?.description}
              required
            />
          </Grid>

          {/* State */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>State</InputLabel>
              <Select
                value={formData.state}
                onChange={(e) => {
                  handleChange("state", e.target.value);
                  setSelectedState(e.target.value);
                }}
                label="State"
                required
              >
                <MenuItem value="">-- Select --</MenuItem>
                {Object.keys(statesAndCities).map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* City */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>City</InputLabel>
              <Select
                value={formData.city}
                onChange={(e) => handleChange("city", e.target.value)}
                label="City"
                required
              >
                <MenuItem value="">-- Select --</MenuItem>
                {cityOptions.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Company Size */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Company Size</InputLabel>
              <Select
                value={formData?.companySize}
                onChange={(e) => handleChange("companySize", e.target.value)}
                required
              >
                <MenuItem key="blank" value="">
                  -- Select --
                </MenuItem>
                {companySizes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Job Setting */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Job Setting</InputLabel>
              <Select
                value={formData?.setting}
                label="Job Setting"
                onChange={(e) => handleChange("setting", e.target.value)}
                required
              >
                <MenuItem value="">-- Select --</MenuItem>
                {settings.map((setting) => (
                  <MenuItem key={setting} value={setting}>
                    {setting}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Job Hours */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Job Hours</InputLabel>
              <Select
                value={formData?.jobHours}
                label="Job Hours"
                onChange={(e) => handleChange("jobHours", e.target.value)}
                required
              >
                <MenuItem value="">-- Select --</MenuItem>
                {jobHours.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Salary Range */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Salary Range</InputLabel>
              <Select
                value={formData?.salaryRange}
                label="Salary Range"
                onChange={(e) => handleChange("salaryRange", e.target.value)}
                required
              >
                <MenuItem value="">-- Select --</MenuItem>
                {salaryRangeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button variant="contained" fullWidth type="submit" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default CreateJob;
