import React from "react";
import { useSelector } from "react-redux";

// Wrapper component that provides user profile information
const UserNameWrap = (props) => {
  const profile = useSelector((state) => state.authReducer.user);

  // Safely access displayName from the profile object
  const displayName = profile?.name || "User"; // Fallback to 'User' if not available

  return (
    <div>
      <h2>
        👋 Hi, Welcome {displayName}
      </h2>
      {/* Render the rest of your component */}
      {props.children}
    </div>
  );
};

export default UserNameWrap;
