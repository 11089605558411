import React, { useEffect, useState } from "react";
import "./Home.css";
import { Button, Col, Container, Row } from "react-bootstrap";
// import thumbsHP from "../assets/thumbsHPpng";
const Home = () => {
  const myRef = React.useRef(null)

   const scrollDiv = () => myRef.current.scrollIntoView()
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="home-container">
      <section className="hero-section">
        <div className="hero-wrap" style={{ backgroundPositionY: 0.7 * scrollY }}>
          <div className="overlay"></div>
          <div style = {{marginTop: "105px"}}className="hero-text">
            <div className="text-center"
            >
               <div style={{
              // fontWeight: "0", fontSize: "75px", fontFamily: "Akzidenz-Grotesk"}}>1Stop4SPOT
              fontWeight: "925", fontSize: "75px", fontFamily: "Sans"}}>
              </div> 
               <div style={{
               fontWeight: "bold", fontSize: "35px", fontStyle: "italic" }}className="py-3">
             {/* "Jobs for SLPS, OTs and More to Come!" */}
             {/* 1Stop 4 Speech Paths and OTs */}
               </div>
              {/* <h3 className="py-3">
                Post Jobs to FB!
              </h3> 
              <h3 className="py-3">
                 SLP Marketplace!
              </h3>   */} 
               <div className="text-center">
                 {/* <Button  onClick={scrollDiv}
                 variant="light "
                  style={{
                   
                    marginTop: "45px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    borderRadius: "15px",
                    textTransform: "uppercase",
                    color: "#194F96",
                    fontSize: "26px"
                    
                  }}
                  // size="lg"
                >
                  Explore
                </Button>  */}

              </div> 
            </div>
          </div>
        </div>
      </section>
      <section className="service-section myl ">
        <Container>
          <Row>
            <Col  md={12}>
              <span style={{fontSize:"25px"}}className="tag">Services</span>
              {/* <h2 className="heading my-2">Solutions for SLPs</h2>  */}
            </Col>
          </Row>
          <Row className="my-2 justify-content-center">
            <Col className ="d-flex align-items-stretch" md={4}>
              <div className="card1">
                <h2>Tailored Job Postings</h2>
                <p className="small">
                  No more digging for accurate job decriptions and job specific information 
                  that is important to the daily grind of the SLP that can ultimately lead to burn out 
                  and low pay. 
                </p>
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
            <Col className ="d-flex align-items-stretch" md={4}>
              <div className="card1">
                <h2 >Create Jobs</h2>
                <p className="small">
                 Once your job is created, it will automatically be posted to our facebook
                 pages for everyone to share and see. </p> 
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="my-2  justify-content-center">
            <Col className ="d-flex align-items-stretch" md={4}>
              <div className="card1">
                <h2 >SLPs</h2>
                {/* <h2 >SLP Marketplace</h2> */}
                 <p className="small">
                  {/* The first and only SLP Marketplace that caters to SLPs with minimal fees. 
                  Use stripe to manage and own your own online store with data insights and 
                  be incharge of your own store front and customers.  You are in control of everything!  */}
                </p> 
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
            <Col className ="d-flex align-items-stretch" md={4}>
              <div className="card1 ">
                <h2 ref={myRef}> Profiles </h2>
                <p className="small">
                  Have your own profile to share jobs, sell items and post content for your own blog to 
                  share to FB and other social media.  
                </p><p className="small"> Find qualified job seekers that are actively looking for employment across different settings.
                </p>
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-us">
        <Container>
          <Row>
            <Col md={12} className="text-end">
              <span style={{fontSize:"25px"}}className="tag">About</span>
              {/* <h2 className="heading my-2">Explore Our Journey</h2> */}
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <img
                src="https://www.nightmarketbangkok.com/wp-content/uploads/2024/08/heartcircle.png"
                className="about-img"
                alt=""
              />
            </Col>
            <Col md={7}>
              <p className="m-3 p-3 pb-0 mb-0">
                The inspiration for 1StopSLP began as a  <a target="_blank" rel="noopener noreferrer" style={{color:"blue"}}href="https://www.facebook.com/groups/365476930551167">
                facebook group</a> on October 17, 2017. 
                As an SLP myself, I felt that we needed to support each other, as our current organization was only helping those at the top and within their inner circle. </p>
                <p className="m-3 p-3 pb-0 mb-0">
                Often, companies would not provide full details about job opportunities, leading us to accept low rates while others took advantage of our hard-earned money. This led me to start allowing only SLP companies to post within the group that appeared to have better intentions for SLPs. I spent some 
                time weeding out job postings to ensure they would benefit individual SLPs and hopefully lead to better retention rates. </p>
                <p className="m-3 p-3 pb-0 mb-0">
                The journey has been slow and challenging, but along the way, I have been learning programming and exploring ways we can unite to strengthen our field by collaborating and supporting each other.</p>
              
              {/* <ul className="m-3 p-3 pb-0 mb-0 mt-0">
                <li>
                  Extensive Job Listings: Access a diverse range of job postings
                  tailored specifically for speech and language pathologists,
                  ensuring you find the perfect fit for your skills and
                  expertise.
                  <li>
                    Personalized Career Support: Receive personalized guidance
                    and support throughout your job search journey, from resume
                    optimization to interview preparation, empowering you to
                    succeed in your career aspirations.
                  </li>
                  <li>
                    Community Engagement: Engage with a vibrant community of SLP
                    professionals, share insights, and stay updated on industry
                    trends and events, fostering collaboration and professional
                    growth.
                  </li>
                </li>
              </ul> */}

              {/* <Button
                style={{
                  paddingRight: "50px",
                  paddingLeft: "50px",
                  borderRadius: "25px",
                  textTransform: "uppercase",
                  // color: "#93b8e8"
                }}
                className="my-3" 
                
              >
                Learn More
              </Button> */}
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="pricing-section myl ">
        <Container>
          <Row className="my-4">
            <Col md={12} className="text-center">
              <span className="tag">Pricing</span>
              <h2 className="heading my-2">
                Choose A Plan That's right For You
              </h2>
            </Col>
          </Row>
          <Row className="myl align-items-center justify-content-evenly">
            <Col md={3}>
              <div className="card1 pricing-card">
                <h3>Basic Plan</h3>
                <h2>$99/Year</h2>
                <ul>
                  <li>Access to Job Listings</li>
                  <li>Limited Support</li>
                  <li>Community Access</li>
                </ul>
                <div className="my-2 px-2 d-grid">
                  <Button>Buy Now</Button>
                </div>
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="card1 pricing-card">
                <h3>Pro Plan</h3>
                <h2>$299/Year</h2>
                <ul>
                  <li>Career Guidance</li>
                  <li>Priority Support</li>
                  <li>Exclusive Webinars</li>
                </ul>
                <div className="my-2 px-2 d-grid">
                  <Button>Buy Now</Button>
                </div>
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="card1 pricing-card">
                <h3>Advance</h3>
                <h2>$399/Year</h2>
                <ul>
                  <li>Unlimited Job Listings</li>
                  <li>24/7 Concierge Support</li>
                  <li>VIP Networking Events</li>
                </ul>
                <div className="my-2 px-2 d-grid">
                  <Button>Buy Now</Button>
                </div>
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <div className="m-5 p-5"></div>
    </div>
  );
};

export default Home;
