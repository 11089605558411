import React, { useState, useEffect, useRef } from "react";
import avatarHolder from '../assets/avatarHolder.png';
import gradientBanner from '../assets/gradientBanner.png';
import { DashIcons } from "../assets/icons/dashIcons";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Select,
  MenuItem,
 
} from "@mui/material";
import { Col, Container, Row, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router";
import { hasError } from "../utils/utils";
import { CompanyService } from "../services";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import Swal from "sweetalert2";
import { auth } from "../firebase-config";
import axios from "axios";
import { getStorage, ref, getDownloadURL, uploadString } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth"; 

const companySizes = [
  "1 - 10",
  "11 - 50",
  "51 - 200",
  "201 - 500",
  "501 - 1000",
  "1000+",
];

const CompanyDetailsPage = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [isMyProfile, setIsMyProfile] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [userId, setUserId] = useState("");
  const [avatarUrl, setAvatarUrl] = useState(avatarHolder);  // Default avatar
  const [coverUrl, setCoverUrl] = useState(gradientBanner);  // Default cover photo
  const [profileData, setData] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    avatar: "",
    bio: "",
    place: "",
    dob: "",
    fb: "",
    insta: "",
    blog: "",
    yt: "",
    twitter: "",
    linkedin: "",
    coverPhoto: "",
    role: "",
    active: "",
  });
  const [error, setError] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    bio: "",
    place: "",
    dob: "",
    notFound: false,
  });
  const [locSuggestions, setLocSuggestions] = useState([]);

  const getLocation = async (value) => {
    if (value.length > 4) {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${value}&countrycodes=US`
        );
        setLocSuggestions(response.data);
      } catch (error) {}
    }
  };

  useEffect(() => {
    let userIdVar;

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (location.pathname.startsWith("/company-details/")) {
        userIdVar = location.pathname.split("/").pop();
        setIsMyProfile(false);
      } else if (user) {
        userIdVar = user.uid;
      }

      if (userIdVar) {
        setError((prev) => ({ ...prev, notFound: false }));
        getProfile(userIdVar);
        setUserId(userIdVar);
        fetchAvatarFromStorage(userIdVar);
        fetchCoverFromStorage(userIdVar);
      }
    });

    return () => unsubscribe();
  }, [location.pathname]);

  // Fetch the avatar from Firebase Storage
  const fetchAvatarFromStorage = async (userId) => {
    try {
      const storage = getStorage();
      const avatarRef = ref(storage, `company/avatar/${userId}`);
      const avatarUrl = await getDownloadURL(avatarRef);
      setAvatarUrl(avatarUrl);
    } catch (error) {
      console.error("Error fetching avatar from storage:", error.message);
      setAvatarUrl(avatarHolder);  // Fallback to default avatar
    }
  };

  // Fetch the cover photo from Firebase Storage
  const fetchCoverFromStorage = async (userId) => {
    try {
      const storage = getStorage();
      const coverRef = ref(storage, `company/cover/${userId}`);
      
      // Try to fetch the download URL only if the user has uploaded a cover photo
      const coverUrl = await getDownloadURL(coverRef);
      setCoverUrl(coverUrl); // Set cover if the file exists
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        // If no cover image exists in Firebase, silently fall back to the default
        console.info("No cover image found, using default cover.");
        setCoverUrl(gradientBanner);  // Use default cover
      } else {
        // Log any other unexpected errors
        console.error("Error fetching cover from storage:", error.message);
        setCoverUrl(gradientBanner);  // General fallback to default cover
      }
    }
  };
  
  

  const isValid = () => {
    let flag = true;
    let errorData = {};
    if (profileData.name === "") {
      flag = false;
      errorData.name = "Full Name cannot be blank";
    }
    if (profileData.bio === "") {
      flag = false;
      errorData.bio = "Bio cannot be blank";
    }
    // if (profileData.phone === "") {
    //   flag = false;
    //   errorData.phone = "Phone Number cannot be blank";
    // }
    setError(errorData);
    return flag;
  };

  const getProfile = async (id) => {
    try {
      const res = await CompanyService.getCompanyDetails(id);
      if (res.exists) {
        const data = res.data();
        setData({
          name: data.name || "",
          username: data.username || "",
          email: data.email || "",
          phone: data.phone || "",
          avatar: "",
          bio: data.bio || "",
          linkedin: data.linkedin || "",
          coverPhoto: "",
          companySize: data.companySize || "",
          hiring: data.hiring || "",
          place: data.place || "",
        });
        setError((prev) => ({ ...prev, notFound: false }));
      } else {
        setError((prev) => ({ ...prev, notFound: true }));
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      Swal.fire({ title: "Error", text: "Unable to get company details" });
      setError((prev) => ({ ...prev, notFound: true }));
    }
  };

  const saveProfile = async () => {
    if (isValid()) {
      setLoading(true);
      try {
        await CompanyService.updateCompanyDetails(userId, profileData);
  
        if (profileData.avatar) {
          await CompanyService.storeCompanyImage(
            profileData.avatar.split("base64,")[1],
            userId,
            "avatar"
          );
          fetchAvatarFromStorage(userId);
          // Dispatch the company avatar updated event
          window.dispatchEvent(new Event("companyAvatarUpdated")); 
        }
  
        if (profileData.coverPhoto) {
          await CompanyService.storeCompanyImage(
            profileData.coverPhoto.split("base64,")[1],
            userId,
            "cover"
          );
          fetchCoverFromStorage(userId);
        }
  
        Swal.fire("Company details Updated!");
        setEditMode(false);
        await getProfile(userId);
      } catch (error) {
        Swal.fire(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire("Please fill all valid details!");
    }
  };
  
  

  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result; 
        if (type === "cover") {
          setData({ ...profileData, coverPhoto: result });
          setCoverUrl(result);  // Update cover photo preview
        } else {
          setData({ ...profileData, avatar: result });
          setAvatarUrl(result);  // Update avatar preview
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadCover = useRef(null);
  const uploadDp = useRef(null);
  const copyLink = () => {
    const url = `${window.location.origin}/company-details/${userId}`;
    const inputElement = document.createElement("input");
    inputElement.value = url;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand("copy");
    document.body.removeChild(inputElement);
    Swal.fire("URL copied to clipboard: " + url);
  };

  const formatAddress = (address) => {
    let addressParts = address.split(",");
    addressParts.forEach((x, i) => {
      if (!isNaN(x)) {
        addressParts.splice(i, 1);
      }
    });
    const lastThreeItems = addressParts.slice(-3);
    return lastThreeItems.join(", ");
  };

  return (
    <>
      {!error.notFound && (
        <Container className="profile-page-container">
          <Row className="justify-content-center">
            <Col md={12} className="p-0">
              <div className="profile-cover-bg">
                <img
                  src={coverUrl}  // Use the updated coverUrl state
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = gradientBanner; 
                  }}
                />
                {editMode && (
                  <span className="edit-cover-btn">
                    <IconButton
                      color="primary"
                      onClick={() => uploadCover.current.click()}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col md={6}>
              <div className="row profile-cover">
                <div className="col-3 avatar-container p-0">
                  <img
                    src={avatarUrl}  // Use the updated avatarUrl state
                    alt="User Avatar"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = avatarHolder; 
                    }}
                    style={{ width: "150px", height: "150px", objectFit: "cover" }}
                  />
                  {editMode && (
                    <span className="edit-dp-btn">
                      <IconButton
                        color="primary"
                        onClick={() => uploadDp.current.click()}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  )}
                </div>
                <div className="col-9 profile-cover-body">
                  <h3>
                    {profileData.name}
                    {isMyProfile && (
                      <IconButton
                        color="primary"
                        onClick={() => setEditMode(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton color="primary" onClick={copyLink}>
                      <ShareIcon />
                    </IconButton>
                  </h3>
                  <p>{profileData.place} | Company</p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <h3>Company Details</h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={3}>
              <TextField
                error={hasError(error.name)}
                label="Company Name"
                variant="standard"
                placeholder="Enter Company Name"
                fullWidth
                value={profileData.name}
                helperText={error.name}
                onChange={(e) =>
                  setData({ ...profileData, name: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
            <Col md={3}>
              <TextField
                error={hasError(error.username)}
                label="Username"
                variant="standard"
                placeholder="Enter Username"
                fullWidth
                helperText={error.username}
                value={profileData.username}
                onChange={(e) =>
                  setData({ ...profileData, username: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center my-3">
            <Col md={3}>
              <TextField
                placeholder="Enter Phone Number"
                label="Phone"
                variant="standard"
                fullWidth
                helperText={error.phone}
                error={hasError(error.phone)}
                value={profileData.phone}
                onChange={(e) =>
                  setData({ ...profileData, phone: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
            <Col md={3}>
              <TextField
                label="Email"
                inputProps={{
                  readOnly: true,
                }}
                focused
                variant="standard"
                fullWidth
                className="d-block"
                value={profileData.email}
              />
            </Col>
          </Row>

          <Row className="justify-content-center my-3">
            <Col md={{ span: 6 }}>
              <TextField
                label="Bio"
                placeholder="Write about your company and its services..."
                multiline
                rows={2}
                variant="filled"
                helperText={error.bio}
                error={hasError(error.bio)}
                value={profileData.bio}
                fullWidth
                onChange={(e) =>
                  setData({ ...profileData, bio: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
          </Row>

          <Row className="my-3">
            <Col md={{ span: 6, offset: 3 }}>
              {editMode ? (
                <Autocomplete
                  disablePortal
                  variant="standard"
                  options={locSuggestions}
                  getOptionLabel={(e) => (e ? e.display_name : "")}
                  onChange={(e, newValue) => {
                    setData({
                      ...profileData,
                      place: formatAddress(newValue.display_name),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      variant="standard"
                      color="primary"
                      label="Location"
                      value={profileData.place}
                      fullWidth
                      onBlur={() => {
                        setLocSuggestions([]);
                      }}
                      onChange={(e) => {
                        getLocation(e.target.value);
                      }}
                    />
                  )}
                  inputProps={{
                    readOnly: !editMode,
                  }}
                />
              ) : (
                <TextField
                  label="Location"
                  variant="standard"
                  fullWidth
                  value={profileData.place}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              )}
            </Col>
          </Row>

          <Row className="justify-content-center my-3">
            <Col md={{ span: 3 }}>
              <FormControl fullWidth>
                <InputLabel>Company Size</InputLabel>
                <Select
                  value={profileData?.companySize}
                  label="Company Size"
                  onChange={(e) => setData({ ...profileData, companySize: e.target.value })}
                  inputProps={{
                    readOnly: !editMode,
                  }}
                >
                  <MenuItem key="blank" value="">
                    -- Select --
                  </MenuItem>
                  {companySizes.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>

            <Col md={{ span: 3 }}>
              <FormControl fullWidth>
                <InputLabel>Actively Recruiting</InputLabel>
                <Select
                  value={profileData?.hiring}
                  label="Actively Recruiting"
                  onChange={(e) => setData({ ...profileData, hiring: e.target.value })}
                  inputProps={{
                    readOnly: !editMode,
                  }}
                >
                  <MenuItem key="1" value="YES">
                    YES
                  </MenuItem>
                  <MenuItem key="2" value="NO">
                    NO
                  </MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>
         
          {editMode && (
            <>
              <Row className="justify-content-center my-3">
                <Col md={6}>
                  <h3>Social Links</h3>
                </Col>
              </Row>

              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Facebook Link"
                    label="Facebook"
                    variant="standard"
                    fullWidth
                    value={profileData.fb}
                    onChange={(e) =>
                      setData({ ...profileData, fb: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Linkedin Link"
                    label="Linkedin"
                    variant="standard"
                    fullWidth
                    value={profileData.linkedin}
                    onChange={(e) =>
                      setData({ ...profileData, linkedin: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Blog Link"
                    label="Blog"
                    variant="standard"
                    fullWidth
                    value={profileData.blog}
                    onChange={(e) =>
                      setData({ ...profileData, blog: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Instagram Link"
                    label="Instagram"
                    variant="standard"
                    fullWidth
                    value={profileData.insta}
                    onChange={(e) =>
                      setData({ ...profileData, insta: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Youtube Link"
                    label="Youtube"
                    variant="standard"
                    fullWidth
                    value={profileData.yt}
                    onChange={(e) =>
                      setData({ ...profileData, yt: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Twitter Link"
                    label="Twitter"
                    variant="standard"
                    fullWidth
                    value={profileData.twitter}
                    onChange={(e) =>
                      setData({ ...profileData, twitter: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
            </>
          )}     
          {!editMode && (
            <>
              {(profileData.fb ||
                profileData.insta ||
                profileData.blog ||
                profileData.linkedin ||
                profileData.twitter ||
                profileData.yt) && (
                <Row className="justify-content-center my-3">
                  <Col md={6}>
                    <h3>Social Links</h3>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-center my-2">
                <Col md={6}>
                  <div className="social-links-container">
                    {profileData.fb && (
                      <span className="social-links">
                        <a href={profileData.fb} target="_blank"rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.fb}></img>
                        </a>
                      </span>
                    )}
                    {profileData.linkedin && (
                      <span className="social-links">
                        <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.linkedin}></img>
                        </a>
                      </span>
                    )}
                    {profileData.twitter && (
                      <span className="social-links">
                        <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.twitter}></img>
                        </a>
                      </span>
                    )}
                    {profileData.insta && (
                      <span className="social-links">
                        <a href={profileData.insta} target="_blank"rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.insta}></img>
                        </a>
                      </span>
                    )}
                    {profileData.yt && (
                      <span className="social-links">
                          <a href={profileData.yt} target="_blank" rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.yt}></img>
                        </a>
                      </span>
                    )}
                    {profileData.blog && (
                      <span className="social-links">
                           <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.blog}></img>
                        </a>
                      </span>
                    )}
                  </div>
               
                </Col>
              </Row>
            </>
          )}

          {editMode && (
            <Row className="justify-content-center my-3">
              <Col md={3} className="d-grid">
                <Button onClick={saveProfile} disabled={loading}>
                  {loading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Saving...
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Col>
              <Col md={3} className="d-grid">
                <Button
                  onClick={() => setEditMode(false)}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}

          <input
            type="file"
            hidden
            name="dp"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, "avatar")}
            ref={uploadDp}
          />
          <input
            type="file"
            hidden
            name="cover"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, "cover")}
            ref={uploadCover}
          />
        </Container>
      )}
      {error.notFound && <h3 className="text-center my-5">Not Found</h3>}
      <div className="pb-5 mb-5"></div>
    </>
  );
};

export default CompanyDetailsPage;
