export const DashIcons = {
  ic_flag_de: require("./ic_flag_de.png"),
  ic_flag_en: require("./ic_flag_en.png"),
  ic_flag_fr: require("./ic_flag_fr.png"),
  ic_notification_chat: require("./ic_notification_chat.png"),
  ic_notification_mail: require("./ic_notification_mail.png"),
  ic_notification_package: require("./ic_notification_package.png"),
  ic_notification_shipping: require("./ic_notification_shipping.png"),
  shapeAvatar: require("./shape-avatar.png"),
  bag: require("./ic_glass_bag.png"),
  buy: require("./ic_glass_buy.png"),
  message: require("./ic_glass_message.png"),
  users: require("./ic_glass_users.png"),
  eye: require("./eye.png"),
  socialMedia: {
    fb: require("./facebook500.png"),
    twitter: require("./twitter.png"),
    insta: require("./instagram.png"),
    blog: require("./blogger.png"),
    linkedin: require("./linkedin.png"),
    yt: require("./youtube-variation.png"),
  },
};
