import { Card, Button, Spinner } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./StripeCSS.css";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import { onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, db } from "./firebase-config";

export default function Stripe() {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [loading, setLoading] = useState(true);
  const [portalLoading, setPortalLoading] = useState(false); // Track loading for the portal button
  const [products, setProducts] = useState([]);
  const [productLoading, setProductLoading] = useState({}); // Track loading for each product

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const currentUser = user.uid;
        startDataListeners(currentUser);
        fetchProducts();
      } else {
        setLoading(false);
        if (!firebase.auth().currentUser) {
          const firebaseUIInstance = firebaseui.auth.AuthUI.getInstance()
            ? firebaseui.auth.AuthUI.getInstance()
            : new firebaseui.auth.AuthUI(firebase.auth());
          firebaseUIInstance.start("#firebaseui-auth-container", {
            signInFlow: "popup",
            signInSuccessUrl: "/",
            signInOptions: [
              firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ],
            credentialHelper: firebaseui.auth.CredentialHelper.NONE,
          });
        }
      }
    });

    const fetchProducts = async () => {
      const productsRef = db.collection("products").where("active", "==", true);
      const productsSnap = await productsRef.get();

      const productsList = [];
      for (const productDoc of productsSnap.docs) {
        const productData = productDoc.data();
        const pricesSnap = await productDoc.ref.collection("prices").get();

        const prices = pricesSnap.docs.map((priceDoc) => ({
          id: priceDoc.id,
          ...priceDoc.data(),
        }));

        productsList.push({ ...productData, prices });
      }
      setProducts(productsList);
    };

    async function startDataListeners(currentUser) {
      db.collection("customers")
        .doc(currentUser)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .onSnapshot(async (snapshot) => {
          if (!snapshot.empty) {
            setHasActiveSubscription(true);
          } else {
            setHasActiveSubscription(false);
          }
          setLoading(false);
        });
    }
  }, []);

  const handlePortalAccess = async () => {
    setPortalLoading(true); // Show the loading spinner
    try {
      const functions = getFunctions();
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url); // Redirect to the customer portal
    } catch (error) {
      alert(`Error accessing customer portal: ${error.message}`);
    } finally {
      setPortalLoading(false); // Hide the loading spinner
    }
  };

  const subscribe = async (event, productId, priceId) => {
    event.preventDefault();
    setProductLoading((prevState) => ({
      ...prevState,
      [productId]: true, // Set loading state only for the clicked product
    }));

    const selectedPrice = {
      price: priceId,
      quantity: 1,
    };

    const checkoutSession = {
      automatic_tax: true,
      tax_id_collection: true,
      collect_shipping_address: true,
      allow_promotion_codes: true,
      line_items: [selectedPrice],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    };

    const docRef = await db
      .collection("customers")
      .doc(auth.currentUser.uid)
      .collection("checkout_sessions")
      .add(checkoutSession);

    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        alert(`An error occurred: ${error.message}`);
        setProductLoading((prevState) => ({
          ...prevState,
          [productId]: false,
        }));
      }
      if (url) {
        window.location.assign(url);
      }
    });
  };

  const renderProducts = () => {
    return (
      <div className="products">
        {products.map((product) => (
          <Card key={product.name} style={{ width: "300px", padding: "15px", margin: "10px" }}>
            {product.images && product.images.length > 0 && (
              <img
                src={product.images[0]}
                alt={product.name}
                style={{ width: "100%", height: "150px", objectFit: "cover", marginBottom: "10px" }}
              />
            )}
            <h2 style={{ fontSize: "18px", margin: "10px 0" }}>{product.name}</h2>
            <p style={{ fontSize: "14px", color: "#555", marginBottom: "10px" }}>{product.description}</p>
            <form onSubmit={(event) => subscribe(event, product.name, product.prices[0].id)}>
              <label htmlFor="price">Choose pricing plan:</label>
              <select id="price" name="price" className="price-select">
                {product.prices.map((price) => (
                  <option key={price.id} value={price.id}>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: price.currency,
                    }).format(price.unit_amount / 100)}{" "}
                    per {price.interval}
                  </option>
                ))}
              </select>
              <Button
                type="submit"
                variant="primary"
                fullWidth
                style={{ marginTop: "10px" }}
                disabled={productLoading[product.name]}
              >
                {productLoading[product.name] ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Subscribe"
                )}
              </Button>
            </form>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <Helmet>
        <title>Stripe Subscriptions</title>
      </Helmet>

      <div id="subscribe">
        <h2 style={{ marginTop: "200px", fontSize: "35px", fontWeight: "700" }}>Access Your Plan</h2>
        {!hasActiveSubscription && !loading && <section className="products">{renderProducts()}</section>}
      </div>

      {!loading && hasActiveSubscription && (
        <section id="my-subscription" className="subscription-card">
          <h2>My subscription</h2>
          <h3>Update subscription & Payment Methods</h3>
          <Button onClick={handlePortalAccess} variant="primary" disabled={portalLoading}>
            {portalLoading ? <Spinner animation="border" size="sm" /> : "Access customer portal"}
          </Button>
        </section>
      )}

      {/* FirebaseUI container for login */}
      <div id="firebaseui-auth-container"></div>
    </Fragment>
  );
}
