import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CreateAltJob from "../components/forms/CreateAltJob";
const CreateAltJobPage = () => {
  const isCompany = useSelector((state) => state.authReducer.isCompany);
  const navigatre = useNavigate();
  useEffect(() => {
    if (!isCompany) navigatre("/jobs");
  }, []);

  return <CreateAltJob />;
};
export default CreateAltJobPage;
