import React from "react";
import {
  createBrowserRouter,
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import {
  LoginPage,
  Home,
  SignUpPage,
  ContactUs,
  MyProfilePage,
  JobListingPublic,
  JobDetailsPublic,
  AltListingPublic,
  AltJobDetailsPublic,
} from "../pages";
import { Footer, SiteNav } from "../components";

const Wrapper = (props) => {
  return (
    <>
      <SiteNav />
      {props.component}
      <Footer />
    </>
  );
};

const router = createHashRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Wrapper component={<Home />} />} />
      <Route path="contact" element={<Wrapper component={<ContactUs />} />} />
      <Route path="login" element={<Wrapper component={<LoginPage />} />} />
      <Route
        path="create-account"
        element={<Wrapper component={<SignUpPage />} />}
      />
      <Route
        path="profiles/:id"
        element={<Wrapper component={<MyProfilePage />} />}
      />
      <Route
        path="/jobs"
        element={<Wrapper component={<JobListingPublic />} />}
      />
      <Route
        path="jobs/:id"
        element={<Wrapper component={<JobDetailsPublic />} />}
      />
      <Route
        path="/jobs-alt"
        element={<Wrapper component={<AltListingPublic />} />}
      />
      <Route
        path="manage-jobs/details/:id"
        element={<Wrapper component={<JobDetailsPublic />} />}
      />
      <Route
        path="manage-jobs/details-alt/:id"
        element={<Wrapper component={<AltJobDetailsPublic />} />}
      />
      <Route path="*" element={<Navigate to={"/"} />} />
    </>
  )
);

const AuthRouter = () => {
  return (
    <>
      <div className="app-body">
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default AuthRouter;
