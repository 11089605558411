import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import {
  CompanyService,
  FirebaseAuthService,
  ProfileService,
} from "../services";
import Swal from "sweetalert2";
import { Checkbox } from "@mui/material";
import firebase from "firebase/compat/app";



const SignUpPage = () => {
  const emailInput = useRef(null);
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    emailId: "",
    name: "",
    password: "",
    rePassword: "",
    isCompany: false,
  });

  const [error, setError] = useState({
    email: "",
    password: "",
    name: "",
    credentials: "",
  });

  const isValid = () => {
    let flag = true;
    let errorData = {};
    if (credentials.emailId === "") {
      flag = false;
      errorData.email = "Email cannot be blank";
    }
    if (credentials.name === "") {
      flag = false;
      errorData.name = "Name cannot be blank";
    }
    if (credentials.password === "") {
      flag = false;
      errorData.password = "Password cannot be blank";
    }
    if (credentials.password !== credentials.rePassword) {
      flag = false;
      errorData.password = "Passwords do not match";
    }
    setError(errorData);
    return flag;
  };

  const onSignup = async () => {
    if (isValid()) {
      try {
        const res = await FirebaseAuthService.handleEmailSignup(
          credentials.emailId,
          credentials.password
        );
        FirebaseAuthService.verifyEmail(res.user);

        if (res.user) {
          Swal.fire("Verification mail has been sent to your mail id");
        }
        createProfile(
          res,
          credentials.name,
          credentials.emailId,
          "",
          "",
          credentials.isCompany,
          'email'
        );
      } catch (error) {
        Swal.fire("Failed to create account. ", error.message);
      }
    }
  };
  // const uidVal = firebase.auth().currentUser.uid;
  const createProfile = (res, name, email, avatar, phone, isCompany, method) => {
    if (res.user) {
      if (isCompany) {
        CompanyService.createCompany(res.user.uid, name, email, avatar, phone);
        

      } else {
        ProfileService.createProfile(res.user.uid, name, email, avatar, phone);
        


      }
      // Check if the method is 'email' or social login and show appropriate alert
    if (method === 'email') {
      Swal.fire("Verification mail has been sent to your email ID.");
    } else {
      Swal.fire("Please login with the social button.");
    }
      console.log(res.user, "res uid")
      // console.log(uidVal, "uid val")
      navigate("/login");
      
    } else {
      Swal.fire("Failed to signup", "Something went wrong");
    }
  };

  const onGoogle = async () => {
    try {
      
      const res = await FirebaseAuthService.handleGoogleLogin();
      
// const user = auth.currentUser;
      if (res.user) {
        const userProfile = await ProfileService.getProfileDetails(
          res.user.uid
        );
        const companyProfile = await CompanyService.getCompanyDetails(
          res.user.uid
        );
        if (userProfile.exists || companyProfile.exists ) {
          
          Swal.fire("Profile Already exists - pls login");
          navigate("/login");
        return;}
        else {
      createProfile(
        res,
        res.user.displayName,
        res.user.email,
        res.user.phoneNumber,
        res.user.photoURL,
        credentials.isCompany,
        'google'
      );
      
      
      
    } }}catch (error) {
      Swal.fire("Failed to create account. ", error.message);
    }
  };

  const onFacebook = async () => {
    try {
      const res = await FirebaseAuthService.handleFacebookLogin();
      
      if (!res.user) return;  // Exit if there is no user
  
      // Fetch user and company profiles
      const [userProfile, companyProfile] = await Promise.all([
        ProfileService.getProfileDetails(res.user.uid),
        CompanyService.getCompanyDetails(res.user.uid)
      ]);
  
      // If either profile exists, log and return
      if (userProfile.exists || companyProfile.exists) {
        console.log("User or company profile already exists.");
        return;  // Exit early since profile already exists
      }
  
      // If no profile exists, proceed to create the profile
      await createProfile(
        res,
        res.user.displayName,
        res.user.email,
        res.user.phoneNumber,
        res.user.photoURL,
        credentials.isCompany,
        'facebook'
      );
      
      console.log("Profile created successfully.");
      
    } catch (error) {
      console.error("Failed to create account:", error.message);
    }
  };
  

  useEffect(() => {
    setTimeout(() => {
      if (emailInput && emailInput.current) emailInput.current.focus();
    }, 1000);
  }, []);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {" "}
      <section className="hero-section">
        <div className="hero-wrap" style={{ backgroundPositionY: 0.7 * scrollY }}>
          <div className="overlay"></div>
          <div className="hero-text">
            <div className="text-center col-lg-8">
              <h1>Create your new account </h1>
              <h3 className="py-3">Signup</h3>
            </div>
          </div>
        </div>
      </section>
      <div className="full-page d-flex align-items-center justify-content-center">
        <div style = {{marginTop:"100px"}}className="login-container">
          <h3 className="my-2">1StopSPOT</h3>
          <p className="my-2">The 1 Stop PLace for Speech Pathologists and Occupational Therapists to Post Jobs and more to come </p>
          <p className="my-2">✅ If you Want to Post Jobs then REGISTER as a Company</p>
          <p className="my-2">✅ If you Want your Job Automatically Posted to FB then Register with FB</p>
          <p className="my-2">✅ If you Want to Apply to Jobs and Hang out then just Register</p>
          <p className="my-2">✅ This is a site built from the ground up by an SLP that cares about you and your 
            workload, caseload, case management, and everything that comes with giving ethical services to the kiddos but keeping 
            us healthy. ❤️ you guys
          </p>
          {/* <div className="d-flex justify-content-center my-3"> */}
          <div className="d-flex  my-3">
              <div className="company-checkbox">
                <Checkbox
                  checked={credentials.isCompany}
                  onChange={() => {
                    setCredentials({
                      ...credentials,
                      isCompany: !credentials.isCompany,
                    });
                  }}
                />
                <span>Register as a company</span>
              </div>
            </div>
          <div className="login-form">
            <FloatingLabel
              controlId="name"
              label="Full Name"
              className="login-input mb-2"
            >
              <Form.Control
                ref={emailInput}
                placeholder="Full Name"
                value={credentials.name}
                onChange={(e) => {
                  setCredentials({ ...credentials, name: e.target.value });
                }}
              />
              {error.name && <p className="error-input">{error.name}</p>}
            </FloatingLabel>
            <FloatingLabel
              controlId="email"
              label="Email address"
              className="login-input mb-2"
            >
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={credentials.emailId}
                onChange={(e) => {
                  setCredentials({ ...credentials, emailId: e.target.value });
                }}
              />
              {error.email && <p className="error-input">{error.email}</p>}
            </FloatingLabel>
            <FloatingLabel
              controlId="password"
              label="Create Password"
              className="login-input mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Password"
                value={credentials.password}
                onChange={(e) => {
                setCredentials({ ...credentials, password: e.target.value });
                }}
              />
              {error.password && (
                <p className="error-input">{error.password}</p>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="repassword"
              label="Re Enter Password"
              className="login-input mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Password"
                value={credentials.rePassword}
                onChange={(e) => {
                  setCredentials({
                    ...credentials,
                    rePassword: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onSignup();
                }}
              />
              {error.password && (
                <p className="error-input">{error.password}</p>
              )}
            </FloatingLabel>
            {/* <div className="d-flex justify-content-center my-3">
              <div className="company-checkbox">
                <Checkbox
                  checked={credentials.isCompany}
                  onChange={() => {
                    setCredentials({
                      ...credentials,
                      isCompany: !credentials.isCompany,
                    });
                  }}
                />
                <span>Register as a company</span>
              </div>
            </div> */}
            <div className="d-grid my-3">
              {error.credentials && (
                <p className="error-input">{error.credentials}</p>
              )}
              <Button onClick={onSignup}>Create Account</Button>
              <p className="text-center m-2">Or</p>
              <Button
                className="my-2"
                variant="outline-primary"
                onClick={onGoogle}
              >
                Signup with Google
              </Button>
              <Button className="btn-fb my-2" onClick={onFacebook}>
                Signup with Facebook
              </Button>

              <p className="my-3 text-center">
                Already have an account?{" "}
                <span
                  className="text-primary btn-link"
                  onClick={() => navigate("/login")}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 py-5"></div>
    </>
  );
};
export default SignUpPage;