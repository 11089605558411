import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, ListGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import {
  CompanyService,
  FirebaseAuthService,
  JobService,
  ProfileService,
} from "../services";
import { useSelector } from "react-redux";
import "./JobDetails.css";
import { AppBackgrounds } from "../assets/background/background";

const JobDetailsSeeker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobDetails, setDetails] = useState();
  const user = useSelector((state) => state.authReducer.auth);
  useEffect(() => {
    getJobDetails();
  }, []);
  const getJobDetails = async () => {
    const jobId = location.pathname.split("/").pop();
    const userId = FirebaseAuthService.getUserId();
    try {
      const res = await JobService.getJobDetails(jobId);
      const details = res.data();
      const rec = await CompanyService.getCompanyDetails(details.recruiter);
      const ca = await JobService.checkApplication(jobId, userId);
      const appl = await res.ref.collection("applicants").get();
      setDetails({
        ...details,
        jobId: jobId,
        recruiterDetails: rec.data(),
        applied: ca.exists,
        applicants: appl.docs.map((x) => x.data()),
      });
    } catch (error) {
      Swal.fire({ text: "Error while fetching Jobe Details" });
    }
  };
  const appplyJob = async () => {
    const userId = FirebaseAuthService.getUserId();
    try {
      const res = await JobService.applyJob(jobDetails.jobId, userId);
      setDetails({ ...jobDetails, applied: true });
      Swal.fire({ text: "Applied for job" });
    } catch (error) {
      Swal.fire({ text: "Error while applying for job" });
    }
  };
  const checkStatus = () => {
    let flag = false;
    jobDetails?.applicants?.forEach((i) => {
      if (i.id === user.uid && i.selected === true) flag = true;
    });
    return flag;
  };
  return (
    <>
      <Container style={{ fontFamily: "MontSerrat" }}>
        <Row>
          <Col md={6}>
            <h2 style={{ fontWeight: "bold" }}>{jobDetails?.title}</h2>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "5px 5px 20px #0005",
                overflow: "hidden",
                maxHeight: "300px",
              }}
            >
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/jobs%2F${jobDetails?.jobId}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = AppBackgrounds.bg_1;
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div className="my-3"></div>
          </Col>
          <Col md={6}>
            <h3>Recruiter</h3>
            <hr />
            <div className="profile-chip my-4">
              <div className="avatar">
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/company%2Favatar%2F${jobDetails?.recruiter}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`}
                />
              </div>
              <div className="name">
                <h6>{jobDetails?.recruiterDetails.name}</h6>
                {/* <p>{jobDetails?.recruiterDetails.email}</p> */}
              </div>
            </div>
            <h3>Job Description</h3>
            <hr />
            <p>{jobDetails?.description}</p>
            {jobDetails?.status === "active" ? (
              <div className="d-grid">
                {!jobDetails?.applied && (
                  <Button onClick={appplyJob}>Apply For The Job</Button>
                )}
                {jobDetails?.applied && (
                  <Button>Already Applied For The Job</Button>
                )}
              </div>
            ) : (
              <div className="d-grid">
                {checkStatus() ? (
                  <Button variant="success">
                    You have been selected for this role. Please contact the
                    recruiter
                  </Button>
                ) : (
                  <Button variant="danger">
                    This requirement has been closed
                  </Button>
                )}
              </div>
            )}
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={12}>
            <div
              style={{
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                minWidth: "316px",
                padding: "20px",
                marginTop: "20px",
                margin: "40px",
                marginRight: "none",
                backgroundColor: "#F6F6F6",
              }}
            >
              <h3>Brief Details seek</h3>
              <hr />
              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Created Date
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {jobDetails?.creationTime?.split("T")[0]}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup as="ul" horizontal className="list-group">
                <ListGroup.Item as="li" className="list-group-left">
                  Status
                </ListGroup.Item>
                <ListGroup.Item as="li" className="list-group-right">
                  {" "}
                  {jobDetails?.status}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Company Size
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.companySize}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Role
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.classification}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Location
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.location}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Hours
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.jobHours}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Payroll Category?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.payrollCategory}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Salary Range / Hr
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.salaryRange}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Type
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.type}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Teletherapy Platform Used
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.telepathyPlatform}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Benefits
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.benefits}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Benefits Details
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.benefitDetails}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  PSLF Eligible?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.pslf}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  PSLF Details
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.pslfDetails}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Is Direct Hire
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.isDirectHire}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Is Indirect Time Paid?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.isIndirectTimePaid}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Details of Paid Indirect time
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.inDirectTimePaidDetails}
                </ListGroup.Item>
              </ListGroup>

              {/* <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Title
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.title}
                </ListGroup.Item>
              </ListGroup> */}

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Population Served
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.populationServed.join(", ")}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Therapy Ind/Group Sizes
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.directTherapyStudents.join(", ")}{" "}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Caseload Management
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.caseloadMgmt}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Caseload Size
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.slpStudents}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  How Many sites?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.sitesToCover}
                </ListGroup.Item>
              </ListGroup>

              {/* <ListGroup horizontal className = "list-group">
              <ListGroup.Item className = "list-group-left">Tax Classification
              </ListGroup.Item>
              <ListGroup.Item className = "list-group-right"> {jobDetails?.taxClassification}
              </ListGroup.Item>
              </ListGroup> */}
            </div>
          </Col>
          <Row>
            <Col md={12}>
              <div
                style={{
                  borderRadius: "30px",
                  boxShadow: "5px 5px 20px #0003",
                  overflow: "hidden",
                  padding: "20px",
                  margin: "40px",
                  minWidth: "316px",
                  marginTop: "20px",
                  backgroundColor: "#F6F6F6",
                }}
              >
                <h3>Hot Job#2</h3>
                <hr />

                <ListGroup horizontal className="list-group">
                  <ListGroup.Item className="list-group-left">
                    Title
                  </ListGroup.Item>
                  <ListGroup.Item className="list-group-right">
                    {" "}
                    {jobDetails?.title}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
          {/* <Col md={4}>
          <div
              style={{
                
                
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                overflow: "hidden",
                padding: "20px",
                margin: "40px",
                minWidth: "316px",
                // marginLeft:"25px",
                backgroundColor:"white"
              }}
            >
            <h3>Hot Job#1</h3>
            <hr />

            <ListGroup horizontal className = "list-group">
              <ListGroup.Item className = "list-group-left">Title
              </ListGroup.Item>
              <ListGroup.Item className = "list-group-right"> {jobDetails?.title}
              </ListGroup.Item>
              </ListGroup>
         
              </div>        
           </Col> */}
        </Row>
      </Container>
    </>
  );
};

const InfoCard = (props) => {
  return (
    <div
      style={{
        borderBottom: "1px solid #AAA3",
        padding: "5px",
        marginBottom: "10px",
        fontSize: "16px",
        display: "flex",
      }}
    >
      <div style={{ width: "30%" }}>{props?.label}</div>
      <div style={{ width: "70%" }}> {props.value}</div>
    </div>
  );
};

export default JobDetailsSeeker;
