import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ProfileCard from "../components/ProfileCard";
import { ProfileService } from "../services/Profile";
import { Button, Col, Container, Row, Card } from "react-bootstrap";
import { CompanyService } from "../services/Company";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
  
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Face2Sharp } from "@mui/icons-material";

const ProfilePage = () => {
  const [profiles, setProfiles] = useState([]);
  const [search, setSearch] = useState({
    name: "",
    location: "",
    role: "",
  });
  const classifications = [
    "SLP",
    "SLPA",
    "SLP - CF",
    // "SLP Supervisor",
    "Virtual Assistant",
    "Paraprofessional",
  ];
  const location = useLocation();
  const getProfiles = async () => {
    try {
      const res = await ProfileService.getProfiles();
      if (!res.empty) {
        let result = res.docs.map((i) => i.data());
        result = result.filter((i) => {
          const { name, location, role } = search; // Destructure filters object
          console.log(search, i.place);
          return (
            (!location ||
              i.place?.toLowerCase().includes(location.toLowerCase())) &&
            (!role || i.role?.toLowerCase() === role.toLowerCase()) &&
            (!name || i.name?.toLowerCase().includes(name.toLowerCase()))
          );
        });
        setProfiles(result);
      } else {
      }
    } catch (error) {
      alert(error.message);
    }
  };
  // const getCompaniess = async () => {
  //   try {
  //     const res = await CompanyService.getCoompanyName(search);
  //     if (!res.empty) {
  //       setCompanies(res.docs.map((i) => i.data()));
  //     } else {
  //     }
  //   } catch (error) {
  //     alert(error.message);
  //   }
  // };
  const refreshPage = () => {
    window.location.reload(false);
  }

  const clear = () => {
    refreshPage();
    setSearch({
      name: "",
      location: "",
      role: "",
    });
    getProfiles();
  };
  const dosearch = () => {
    getProfiles();
    // getCompaniess();
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={3}>
            <TextField
              fullWidth
              label="Search Profiles..."
              name="clientName"
              className="mt-0"
              variant="outlined"
              value={search.name}
              onChange={(e) => setSearch({ ...search, name: e.target.value })}
              type="text"
            />
          </Col>
          <Col md={3}>
            <FormControl fullWidth>
              <InputLabel>Job Role</InputLabel>
              <Select
                value={search?.role}
                label="Job Role"
                onChange={(e) => setSearch({ ...search, role: e.target.value })}
              >
                {classifications.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <TextField
              fullWidth
              label="Location"
              className="mt-0"
              variant="outlined"
              value={search.location}
              onChange={(e) =>
                setSearch({ ...search, location: e.target.value })
              }
              type="text"
            />
          </Col>
          <Col md={3}>
            <Button onClick={dosearch}>Search</Button>
            {(search.location || search.name || search.role) && (
              <button
                type="button"
                className="btn btn-outline-primary border-0"
                onClick={clear}
                
              >
                Clear
              </button>
            )}{" "}
          </Col>

          <Col md={1}></Col>
        </Row>
        <Row>
          {profiles.map(
            (i) =>
              i.username && (
                <Col md={3} key={i.name} className="my-2">
                  <ProfileCard profile={i} />
                </Col>
              )
          )}
        </Row>
        {/* <Row>
          {companies.map(
            (i) =>
              i.username && (
                <Col md={3} key={i.name} className="my-2">
                  <ProfileCard profile={i} />
                </Col>
              )
          )}
        </Row> */}
        {!search && profiles.length < 1 && (
          <h2 className="text-center py-5 my-5 text-secondary">
            Search for profiles
          </h2>
        )}
        {search && profiles.length < 1 && (
         <div style={{
          width:"250px",
          height: "300px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "50px",
          
         }}
         className="profile-card">
         <div className="images">
           <div className="cover-img">
             <img
               src=
                 "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9PJscu_d_qoxDIWQnCEU-KF-6WpzI0hbMS-TmuUeaWw&s"
              
              alt=""
             />
           </div>
           <div className="profile-img">
              <img
            
               src=
                 "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6klOi0UrG5l8Zhn8dnB3eJV_npa5aXJJk08pLp0yPvg&s"
              
             alt=""
              /> 
             {/* {profile.active === "YES" && (
               <img src={DashIcons.eye}  />
             )}{" "} */}
           </div>
         </div>
         <div className="profile-body">
           {/* <h4>John Doe</h4>
           <p className="uname">@JDoe</p>
           <p className="place">Lost</p> */}
         
           <div className="d-grid">
              
           </div>
         </div>
       </div>
        )}
      </Container>
    </>
  );
};

export default ProfilePage;
