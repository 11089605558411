import {
  FacebookAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { app } from "../firebase-config";
import { ProfileService,  } from "../services";
import firebase from "firebase/compat/app";
const auth = getAuth(app);
  

  


export class FirebaseAuthService {
  
  static handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();
    const user = auth.currentUser
    // const auth = getAuth();
    // signInWithRedirect(auth, provider);
    
    return signInWithPopup(auth, provider);
  };

  static handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  static handleEmailSignup = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  static handleEmailSignin = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  static verifyEmail = async (user) => {
    return sendEmailVerification(user);
  };
  static logout = async () => {
    localStorage.removeItem("isAuth");
    localStorage.removeItem("user");
    return signOut(auth);
  };
  static getUserId = () => {
    return JSON.parse(localStorage.getItem("user")).uid;
  };
}