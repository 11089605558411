import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { Button, Container, Row, Col, Spinner, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router";
import { JobCardSeeker } from "../components";
import Swal from "sweetalert2";
import { CompanyService, JobService } from "../services";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import debounce from "lodash.debounce";

const JobListingPublic = () => {
  const [jobs, setJobs] = useState([]);
  const [jobsF, setJobsF] = useState([]); // Filtered jobs
  const [totalJobs, setTotalJobs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    isLastPage: false,
  });
  const [lastJob, setLastJob] = useState(null); // Track the last fetched job's ID
  const itemsPerPage = 6; // 6 jobs per page
  const initialItems = 18; // First 18 jobs to load (for the first 3 pages)

  const navigate = useNavigate();

  // Ref to track the job listings section
  const jobListingRef = useRef(null);

  const [filters, setFilters] = useState({
    slpStudents: ["N/A", "1 - 20", "20 - 40", "40 - 60", "60 - 80", "80 - 100"],
    salary: ["20 - 30", "30 - 40", "40 - 50", "50 - 60", "60 - 70", "70 - 80", "80 or more"],
    companies: [],
    setting: ["School", "Clinic / Outpatient", "Home Health", "Hospital", "Acute Rehab", "Longterm Rehab", "University / Academic", "Non-Clinical"],
    type: ["Hybrid", "Remote", "Inperson"],
    jobHours: ["Part-Time", "Full-Time", "PRN"],
    payrollCategory: ["1099", "W-2"],
    pslf: ["Yes", "No"],
    benefits: ["Yes", "No"],
    isDirectHire: ["Yes", "No"],
    classification: ["SLP", "SLPA", "SLP - CF", "SLP Supervisor", "Virtual Assistant", "Paraprofessional"],
    companySize: ["1 - 10", "11 - 50", "51 - 200", "201 - 500", "501 - 1000", "1000+"],
  });

  const [appFilters, setAppFilters] = useState({
    title: "",
    load: [],
    salary: [],
    companies: [],
    setting: [],
    type: [],
    payrollCategory: [],
    jobHours: [],
    pslf: [],
    benefits: [],
    isDirectHire: [],
    classification: [],
    companySize: [],
    location: "",
  });

  const hasActiveFilters = useMemo(() => {
    return Object.values(appFilters).some(
      (filter) => (Array.isArray(filter) ? filter.length > 0 : filter !== "")
    );
  }, [appFilters]);

  // Fetch jobs with reset option
  const getAllJobs = useCallback(
    async (fetchSize = initialItems, start = null, reset = false) => {
      if (loading) return; // Prevent multiple API calls when loading

      try {
        setLoading(true);

        if (reset) {
          // Clear jobs and reset pagination before fetching new results
          setJobs([]);
          setLastJob(null); // Reset last job reference
          setPagination({
            page: 1,
            isLastPage: false,
          });
          setTotalJobs(0); // Reset total job count
        }

        // Fetch jobs using the last job cursor if provided
        const res = await JobService.getAllJobs(fetchSize, start, false, appFilters);

        const isLastPage = res.docs.length < fetchSize;

        const jobPromises = res.docs.map(async (i) => {
          const jobDetails = i.data();
          const rec = await CompanyService.getCompanyDetails(jobDetails.recruiter);
          const applicants = await i.ref.collection("applicants").get();
          return {
            ...jobDetails,
            id: i.id,
            recruiterDetails: rec.data(),
            applicants: applicants.docs.map((x) => x.data()),
          };
        });

        const jobAry = await Promise.all(jobPromises);

        // Append jobs or replace if reset
        setJobs((prevJobs) => (reset ? jobAry : [...prevJobs, ...jobAry]));

        // Track the last job document (cursor for the next batch)
        if (res.docs.length > 0) {
          setLastJob(res.docs[res.docs.length - 1]); // Set the last job document
        }

        setPagination((prevPagination) => ({
          ...prevPagination,
          isLastPage,
        }));

        setTotalJobs((prevTotal) => (reset ? res.size : prevTotal + res.size)); // Update total job count
        setFilters((prevFilters) => ({
          ...prevFilters,
          companies: [...new Set([...prevFilters.companies, ...jobAry.map((x) => x.recruiterDetails.name)])],
        }));
      } catch (error) {
        console.error(error);
        Swal.fire({ text: "Error while fetching jobs" });
      } finally {
        setLoading(false);
      }
    },
    [appFilters, loading]
  );

  // Apply filters to jobs
  const applyFilters = useCallback(() => {
    let filteredJobs = jobs;

    filteredJobs = filteredJobs.filter((x) => {
      const nameMatch =
        !appFilters.title ||
        x.title.toLowerCase().includes(appFilters.title.toLowerCase());
      const caseload =
        !(appFilters.load.length > 0) || appFilters.load.includes(x.slpStudents);
      const salary =
        !(appFilters.salary.length > 0) || appFilters.salary.includes(x.salaryRange);
      const company =
        !(appFilters.companies.length > 0) ||
        appFilters.companies.includes(x.recruiterDetails.name);
      const type =
        !(appFilters.type.length > 0) || appFilters.type.includes(x.type);
      
      const classification =
        !(Array.isArray(appFilters.classification) && appFilters.classification.length > 0) ||
        appFilters.classification.some(
          (filter) => filter.toLowerCase() === x.classification.toLowerCase()
        );

      const payrollCategory =
        !(appFilters.payrollCategory.length > 0) ||
        appFilters.payrollCategory.includes(x.payrollCategory);
      const jobHours =
        !(appFilters.jobHours.length > 0) || appFilters.jobHours.includes(x.jobHours);
      const pslf =
        !(appFilters.pslf.length > 0) || appFilters.pslf.includes(x.pslf);
      const isDirectHire =
        !(appFilters.isDirectHire.length > 0) ||
        appFilters.isDirectHire.includes(x.isDirectHire);
      const benefits =
        !(appFilters.benefits.length > 0) || appFilters.benefits.includes(x.benefits);
      const setting =
        !(appFilters.setting.length > 0) || appFilters.setting.includes(x.setting);
      const cs =
        !(appFilters.companySize.length > 0) ||
        appFilters.companySize.includes(x?.companySize);
      const location =
        !appFilters.location ||
        x.location.toLowerCase().includes(appFilters.location.toLowerCase());

      return (
        nameMatch &&
        caseload &&
        salary &&
        company &&
        type &&
        classification &&
        payrollCategory &&
        jobHours &&
        pslf &&
        isDirectHire &&
        benefits &&
        setting &&
        cs &&
        location
      );
    });

    setJobsF(filteredJobs);
  }, [appFilters, jobs]);

  // Trigger filter application whenever jobs or filters change
  useEffect(() => {
    applyFilters();
  }, [jobs, appFilters, applyFilters]);

  // Debounced search handler
  const handleSearch = useCallback(
    debounce(() => {
      getAllJobs(initialItems, null, true); // Trigger a reset when applying filters
    }, 300),
    [appFilters, getAllJobs]
  );

  // Clear filters
  const clearFilter = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All filters will be cleared.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, clear filters!",
    }).then((result) => {
      if (result.isConfirmed) {
        setAppFilters({
          title: "",
          load: [],
          salary: [],
          companies: [],
          setting: [],
          type: [],
          payrollCategory: [],
          jobHours: [],
          pslf: [],
          benefits: [],
          isDirectHire: [],
          classification: [], 
          companySize: [],
          location: "",
        });
        handleSearch();
      }
    });
  };

  // Load more jobs (next batch of jobs starting after the last fetched job)
  const loadMoreItems = () => {
    if (!pagination.isLastPage && !loading) {
      getAllJobs(itemsPerPage * 3, lastJob); // Fetch 3 pages of jobs after the last fetched job
    }
  };

  // Change page with smooth scrolling
  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: newPage,
      }));

      // Scroll to the job listings section when page changes
      if (jobListingRef.current) {
        window.scrollTo({
          top: jobListingRef.current.offsetTop - 100, // Adjust offset for any padding or filters
          behavior: "smooth", // Smooth scrolling
        });
      }
    }
  };

  // Calculate total pages
  const totalPages = useMemo(() => Math.ceil(jobsF.length / itemsPerPage), [jobsF]);

  // Initial load with useEffect
  useEffect(() => {
    getAllJobs(); // Initial load
  }, []); // Empty dependency array to ensure this only runs on the first render

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section className="hero-section">
        <div className="hero-wrap" style={{ backgroundPositionY: 0.7 * scrollY }}>
          <div className="overlay"></div>
          <div className="hero-text">
            <div className="text-center col-lg-8">
              <h1>Find jobs as per your requirement</h1>
              <h3 className="py-3">Jobs</h3>
            </div>
          </div>
        </div>
      </section>
      <Container style={{ marginTop: "35px" }}>
        <Row className="mb-4">
          <Col>
            <div className="filters">
              {/* Filters */}
              <Row
                style={{ padding: "7.5px", paddingBottom: "0px" }}
                className="justify-content-center align-items-center my-2 mt-4"
              >
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Job Setting</InputLabel>
                    <Select
                      value={appFilters.setting}
                      label="Job Setting"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          setting: e.target.value,
                        })
                      }
                    >
                      {filters.setting.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Job Type</InputLabel>
                    <Select
                      value={appFilters.type}
                      label="Job Type"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          type: e.target.value,
                        })
                      }
                    >
                      {filters.type.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Job Role</InputLabel>
                    <Select
                      value={appFilters.classification}
                      label="Job Role"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          classification: Array.isArray(e.target.value)
                            ? e.target.value
                            : [e.target.value], 
                        })
                      }
                    >
                      {filters.classification.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={3}>
                  <TextField
                    type="text"
                    variant="outlined"
                    color="primary"
                    label="Location"
                    onChange={(e) =>
                      setAppFilters({
                        ...appFilters,
                        location: e.target.value,
                      })
                    }
                    value={appFilters.location}
                    fullWidth
                  />
                </Col>
                </Row>
                <Row className ="row d-flex justify-content-center"style={{  paddingTop: "7.5px" }}>
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Payroll Category</InputLabel>
                    <Select
                      value={appFilters.payrollCategory}
                      label="Payroll Category"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          payrollCategory: e.target.value,
                        })
                      }
                    >
                      {filters.payrollCategory.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Job hours</InputLabel>
                    <Select
                      value={appFilters.jobHours}
                      label="Job Hours"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          jobHours: e.target.value,
                        })
                      }
                    >
                      {filters.jobHours.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              {/* Clear Filters */}
              <Row
                style={{ paddingTop: "7.5px" }}
                className="justify-content-center"
              >
                {hasActiveFilters && (
                  <Col lg={3} className="d-grid">
                    <h6 style={{ paddingTop: "10px", textAlign: "center", color: "red" }}>
                      Please Clear Filter if any problems
                    </h6>
                    <button
                      className="btn btn-success"
                      onClick={clearFilter}
                    >
                      Clear
                    </button>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Button onClick={() => navigate("/jobs-alt")}>
              Browse Alternate Jobs
            </Button>
        </Row>
        <Row ref={jobListingRef}>
          {!loading &&
            jobsF
              .slice((pagination.page - 1) * itemsPerPage, pagination.page * itemsPerPage)
              .map((item) => (
                <Col md={4} key={item.id}>
                  <JobCardSeeker
                    job={item}
                    onClick={() => navigate("/manage-jobs/details/" + item.id)}
                  />
                </Col>
              ))}
        </Row>
        {jobsF.length < 1 && !loading && (
          <h3 className="text-secondary text-center my-4">No Jobs Found</h3>
        )}
        {loading && (
          <div className="text-center mt-5 pt-5">
            <Spinner />
          </div>
        )}

        {!loading && jobsF.length > 0 && (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <Pagination className="my-2 align-items-center">
                <Pagination.First onClick={() => changePage(1)} disabled={pagination.page === 1 || loading} />
                <Pagination.Prev onClick={() => changePage(pagination.page - 1)} disabled={pagination.page === 1 || loading} />
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item key={index + 1} active={pagination.page === index + 1} onClick={() => changePage(index + 1)} disabled={loading}>
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => changePage(pagination.page + 1)} disabled={pagination.page === totalPages || loading} />
                <Pagination.Last onClick={() => changePage(totalPages)} disabled={pagination.page === totalPages || loading} />
              </Pagination>
            </div>

            {/* Only show Load More button on the 3rd page */}
            {pagination.page === 3 && !pagination.isLastPage && (
              <div className="d-flex justify-content-center">
                <Button onClick={loadMoreItems}>Load More Jobs</Button>
              </div>
            )}
          </>
        )}
      </Container>
      <div className="mb-5 pb-5"></div>
    </>
  );
};

export default JobListingPublic;
