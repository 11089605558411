import React, { useEffect } from "react";
import CreateJob from "../components/forms/CreateJob";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
const CreateJobPage = () => {
  const isCompany = useSelector((state) => state.authReducer.isCompany);
  const navigatre = useNavigate();
  useEffect(() => {
    if (!isCompany) navigatre("/jobs");
  }, []);

  return <CreateJob />;
};
export default CreateJobPage;
