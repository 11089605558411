import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { JobCardSeeker } from "../components";
import Swal from "sweetalert2";
import {
  AltJobService,
  JobService,
} from "../services";
import { useSelector } from "react-redux";

const JobApplicationSeeker = () => {
  const [jobs, setJobs] = useState([]); // Regular applied jobs
  const [ajobs, setaJobs] = useState([]); // Alt applied jobs
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.authReducer.auth);

  // Fetch applied jobs (regular jobs)
  const getAllJobs = async () => {
    try {
      console.log("Fetching applied regular jobs for user:", userAuth.uid);
      const jobs = await JobService.getAppliedJobs(userAuth.uid); // Fetch applied regular jobs
      console.log("Applied regular jobs fetched: ", jobs);
      setJobs(jobs); // Set state with applied regular jobs
    } catch (error) {
      console.log("Error fetching applied regular jobs:", error);
      Swal.fire({ text: "Error while fetching applied regular jobs" });
    }
  };

  // Fetch applied alt jobs (alt jobs)
  const getAllAJobs = async () => {
    try {
      console.log("Fetching applied alt jobs for user:", userAuth.uid);
      const altJobs = await AltJobService.getAppliedJobs(userAuth.uid); // Fetch applied alt jobs
      console.log("Applied alt jobs fetched: ", altJobs);
      setaJobs(altJobs); // Set state with applied alt jobs
    } catch (error) {
      console.log("Error fetching applied alt jobs:", error);
      Swal.fire({ text: "Error while fetching applied alt jobs" });
    }
  };
  

  useEffect(() => {
    getAllJobs();  // Fetch applied regular jobs
    getAllAJobs(); // Fetch applied alt jobs
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={6}>
          <h3>Applied Jobs</h3>
        </Col>
        <Col xs={6} className="text-end">
          <Button onClick={() => navigate("/jobs")}>All Jobs</Button>
        </Col>
      </Row>
      <Row>
        {jobs.map((item) => (
          <Col md={4} key={item.id}>
            <JobCardSeeker
              job={item}
              onClick={() => navigate("/manage-jobs/details/" + item.id)}
            />
          </Col>
        ))}
        {jobs.length < 1 && (
          <div style={{
            borderRadius: "10px",
            boxShadow: "5px 5px 20px #0003",
            minWidth: "150px",
            padding: "20px",
            margin: "40px",
            marginRight: "25%",
            marginLeft: "0",
            backgroundColor: "#F6F6F6",
          }}>
            <h3 className="text-secondary text-center my-4">
              You have not applied to any job
            </h3>
          </div>
        )}
      </Row>

      <Row>
        <Col xs={6}>
          <h3>Applied Alt Jobs</h3>
        </Col>
        <Col xs={6} className="text-end">
          <Button onClick={() => navigate("/jobs")}>All Jobs</Button>
        </Col>
      </Row>
      <Row>
        {ajobs.map((item) => (
          <Col md={4} key={item.id}>
            <JobCardSeeker
              job={item}
              onClick={() => navigate("/manage-jobs/details-alt/" + item.id)}
            />
          </Col>
        ))}
        {ajobs.length < 1 && (
          <div style={{
            borderRadius: "10px",
            boxShadow: "5px 5px 20px #0003",
            minWidth: "150px",
            padding: "20px",
            margin: "40px",
            marginRight: "25%",
            marginLeft: "0",
            backgroundColor: "#F6F6F6",
          }}>
            <h3 className="text-secondary text-center my-4">
              You have not applied to any alt job
            </h3>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default JobApplicationSeeker;
