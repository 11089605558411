import { Button, Grid, TextField, Card } from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { ContactUsSchema } from "./contactUsValidationSchema";
import "./ContactUsForm.css";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { useNavigate } from "react-router-dom";  // Import useNavigate

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    clientName: "",
    clientEmail: "",
    message: "",
  });

  const db = firebase.firestore(); // Firestore instance
  const navigate = useNavigate(); // Use useNavigate hook

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Save form data to Firestore
    try {
      await db.collection("contactMessages").add({
        clientName: formData.clientName,
        clientEmail: formData.clientEmail,
        message: formData.message,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

      contactUsMessageSuccess();
      navigate("/");  // Use navigate for redirection
    } catch (error) {
      console.error("Error saving contact message: ", error);
      contactUsMessageError();
    }
  };

  const contactUsMessageSuccess = () => {
    Swal.fire({
      title: "Congratulations!",
      text: "Successfully Sent Message!",
      icon: "success",
      timer: 2000,
      showConfirmButton: false,
    });
  };

  const contactUsMessageError = () => {
    Swal.fire({
      title: "Contact Message failed",
      text: "Make sure all fields are filled correctly!",
      icon: "error",
      timer: 2000,
      showConfirmButton: false,
    });
  };

  const renderError = (message) => <p style={{ color: "red" }}>{message}</p>;

  return (
    <Card className="mb-4 contact-container contact-card shadow-lg p-3 mb-5 bg-body rounded">
      <h2 className="mb-3 font-weight-bold text-center">Contact Us</h2>
      <Formik validationSchema={ContactUsSchema}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid xs={12} sm={12} item>
              <TextField
                fullWidth
                label="Name"
                id="clientName"
                name="clientName"
                variant="outlined"
                required
                value={formData.clientName}
                onChange={handleChange}
                type="text"
              />
              <ErrorMessage name="clientName" render={renderError} />
            </Grid>
            <Grid xs={12} sm={12} item>
              <TextField
                fullWidth
                label="Email"
                id="clientEmail"
                name="clientEmail"
                variant="outlined"
                required
                value={formData.clientEmail}
                onChange={handleChange}
                type="email"
              />
              <ErrorMessage name="clientEmail" render={renderError} />
            </Grid>
            <Grid xs={12} sm={12} item>
              <TextField
                fullWidth
                multiline
                label="Message"
                id="message"
                name="message"
                variant="outlined"
                required
                rows={5}
                value={formData.message}
                onChange={handleChange}
                type="text"
              />
              <ErrorMessage name="message" render={renderError} />
            </Grid>
            <Grid xs={12} sm={12} item>
              <Button
                variant="contained"
                size="large"
                className="w-100"
                color="primary"
                type="submit"
              >
                <span className="btn-wrapper--label">Send</span>
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Card>
  );
};

export default ContactUsForm;
