import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import authReducer from "./authSlice";
const persistConfig = {
  key: "root",
  storage: storage,
};
const rootReducer = combineReducers({
  authReducer,
});
const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: [thunk],
  devTools: true,
});
export default store;
export const peristor = persistStore(store);
