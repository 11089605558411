import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, ListGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import {
  AltJobService,
  FirebaseAuthService,
  JobService,
  ProfileService,
} from "../services";
import "./JobDetails.css";
import { AppBackgrounds } from "../assets/background/background";

const AltJobDetailsRecruiter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobDetails, setDetails] = useState();
  const [seekers, setSeekers] = useState([]);
  useEffect(() => {
    getJobDetails();
  }, []);
  const getJobDetails = async () => {
    const jobId = location.pathname.split("/").pop();
    try {
      const res = await AltJobService.getJobDetails(jobId);
      const details = res.data();
      const applicantsSnapshot = await res.ref.collection("applicants").get();
      const seekersPromises = applicantsSnapshot.docs.map(
        async (applicantDoc) => {
          const applicantData = applicantDoc.data();
          const profile = await ProfileService.getProfileDetails(
            applicantDoc.id
          );
          return { ...applicantData, ...profile.data() };
        }
      );
      const s = await Promise.all(seekersPromises);
      setDetails({
        ...details,
        jobId: jobId,
        applicants: applicantsSnapshot.docs.map((x) => x.data()),
      });
      setSeekers(s);
    } catch (error) {
      console.log(error);
      Swal.fire({ text: "Error while fetching Job Details" });
    }
  };
  const closeReq = async () => {
    try {
      const res = await JobService.closeJob(jobDetails.jobId);
      setDetails({ ...jobDetails, status: "closed" });
      Swal.fire({ text: "Requirement Closed" });
      await getJobDetails();
    } catch (error) {
      Swal.fire({ text: "Error while closing requirement" });
    }
  };

  const selectSeeker = async (id) => {
    try {
      const res = await JobService.selectSeeker(jobDetails.jobId, id);
      await closeReq();
    } catch (error) {
      console.log(error);
      Swal.fire({ text: "Error while selecting candidate" });
    }
  };

  return (
    <>
      <Container style={{ fontFamily: "MontSerrat" }}>
        <Row>
          <Col md={6}>
            <h2 style={{ fontWeight: "bold" }}>{jobDetails?.title}</h2>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "5px 5px 20px #0005",
                overflow: "hidden",
                maxHeight: "300px",
              }}
            >
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/jobs%2F${jobDetails?.jobId}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = AppBackgrounds.bg_1;
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div className="my-3"></div>
          </Col>
          <Col md={6}>
            <h3>Job Description</h3>
            <hr />
            <p>{jobDetails?.description}</p>
            <div className="d-grid">
              {jobDetails?.status === "active" && (
                <Button variant="danger" onClick={closeReq}>
                  Close Requirement
                </Button>
              )}
              {jobDetails?.status === "closed" && <Button>Closed </Button>}
            </div>
          </Col>
        </Row>
        <Row>
          <h3>Applied Candidates</h3>
          <hr />
          {seekers.map((item) => (
            <Col md={6} className="my-2 mb-5">
              <div
                className="profile-chip"
                style={item.selected ? { backgroundColor: "#40D06530" } : {}}
              >
                <div className="avatar">
                  <img src={item.avatar} />
                </div>
                <div className="name">
                  <h6>{item.name}</h6>
                  <p>{item.email}</p>
                </div>
              </div>
              <div className="d-grid">
                <div className="btn-group " role="group">
                  {jobDetails.status === "active" ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => selectSeeker(item.id)}
                    >
                      Select Candidate
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate("/profiles/" + item.id)}
                  >
                    View Profile
                  </button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {/* <div
              style={{
                
                // display:"flex",
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                overflow: "hidden",
                padding: "20px",
                
                // marginLeft:"25px",
                backgroundColor:"white"
              }}
            > */}
        <Row className="justify-content-center">
          <Col md={12}>
            <div
              style={{
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                minWidth: "316px",
                padding: "20px",
                margin: "40px",
                marginTop: "20px",
                marginRight: "none",
                backgroundColor: "#F6F6F6",
              }}
            >
              <h3>Brief Details Recruit</h3>
              <hr />

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Created Date
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {jobDetails?.creationTime?.split("T")[0]}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Status
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.status}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                  <ListGroup.Item className="list-group-left">
                    Title
                  </ListGroup.Item>
                  <ListGroup.Item className="list-group-right">
                    {" "}
                    {jobDetails?.title}
                  </ListGroup.Item>
                </ListGroup>

           

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Setting
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.setting}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Location
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.location}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Hours
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.altJobHours}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Payroll Category?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.payrollCategory}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Salary Range / Hr
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.salaryRange}
                </ListGroup.Item>
              </ListGroup>

              

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Type
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.type}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Benefits
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.benefits}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Benefits Details
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.benefitDetails}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  PSLF Eligible?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.pslf}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  PSLF Details
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.pslfDetails}
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </Container>
    </>
  );
};

const InfoCard = (props) => {
  return (
    <div
      style={{
        borderBottom: "1px solid #AAA3",
        padding: "5px",
        marginBottom: "10px",
        fontSize: "20px",
        display: "flex",
      }}
    >
      <div style={{ width: "35%" }}>{props?.label}</div>
      <div style={{ width: "65%" }}> {props.value}</div>
    </div>
  );
};

export default AltJobDetailsRecruiter;
