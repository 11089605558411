import React from "react";
import { Button } from "react-bootstrap";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer-20192">
      <div className="site-section">
        <div className="container">
          <div className="cta d-block d-md-flex align-items-center px-5">
            <div className="fb-group">
              <h4>Join our facebook group</h4>
              <p className="text-light">
                Join our vibrant Facebook group and become part of a thriving
                community of over 6000 members dedicated to Speech and Language
                Pathology (SLP). Share insights, resources, and experiences,
                connect with fellow professionals, and collaborate to advance in
                your SLP journey. Whether you're seeking support, networking
                opportunities, or simply a place to share your passion for
                communication sciences, our group offers a welcoming space for
                growth and connection. Join us today and be a part of something
                meaningful in the SLP community!"
              </p>
            </div>
            <div className="ml-auto">
              {/* <a href="#" className="btn btn-dark rounded-0 py-3 px-5"> */}
              <Button variant="light"
                  style={{
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    borderRadius: "15px",
                    textTransform: "uppercase",
                    marginLeft:"30px"
                    
                  }}
                  size="lg">
                Join us
                </Button>
              {/* </a> */}
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <a href="#" className="footer-logo">
                1StopSLP
              </a>
              <p className="copyright">&copy; 2024</p>
            </div>
            <div className="col-sm">
              <h3>Customers</h3>
              <ul className="list-unstyled links">
                <li>
                  <a href="#">Job Listing</a>
                </li>
                <li>
                  <a href="#">Profiles</a>
                </li>
              </ul>
            </div>
            <div className="col-sm">
              <h3>Company</h3>
              <ul className="list-unstyled links">
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li>
                <li>
                  <a href="#">Contact us</a>
                </li>
              </ul>
            </div>
            <div className="col-sm">
              <h3>Further Information</h3>
              <ul className="list-unstyled links">
                <li>
                  <a href="#">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h3>Follow us</h3>
              <ul className="social">
                <li>
                  <a href="#">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
     
      <div className="developer">All rights reserved by 1StopSLP, 2024.
      <p>
            Made with ❤️ by an 
            <a
              href="https://github.com/pelagicBKK"
              target="_blank"
              rel="noopener noreferrer"
            >
              SLP for SLPS
            </a>
           
          </p>
          
           
          
        
          </div>
    </footer>
  );
}
