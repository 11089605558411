import { db, storage } from "../firebase-config";

export class CompanyService {
  static createCompany = (id, name, email, phone, avatar) => {
    const docRef = db.collection("companies").doc(id);
    return docRef.set({
      id: id,
      name: name,
      email: email,
      phone: phone,
      avatar: avatar,
    });
  };
  static getCompanyDetails = async (id) => {
    const docRef = db.collection("companies").doc(id);
    return docRef.get();
  };
  static updateCompanyDetails = async (id, data) => {
    const docRef = db.collection("companies").doc(id);
    return docRef.update({
      id: id,
      name: data.name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      bio: data.bio,
      place: data.place,
      linkedin: data.linkedin,
      companySize: data.companySize,
      hiring: data.hiring,
    });
  };
  static getProfiles = (page = 1, limit = 10) => {
    const docRef = db.collection("profile");
    return docRef.get();
  };
  static storeCompanyImage = (image, id, type) => {
    return storage
      .ref(`/company/${type}`)
      .child(id)
      .putString(image, "base64", { contentType: "image/jpg" });
  };
  static getCoompanyName = (name) => {
    const docRef = db.collection("companies").where("name", "==", name);
    return docRef.get();
  };
}
