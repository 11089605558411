import firebase from "firebase/compat/app";
//  import { initializeApp } from 'firebase/app';
import "firebase/compat/auth";
import "firebaseui/dist/firebaseui.css";
// import setIsAuth from  "./components/SiteNavLogin";
// import SiteNavLogin from "./components/SiteNavLogin";
import "firebase/compat/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import "firebase/compat/storage";
import { getPerformance } from "firebase/performance";
import { initializeFirestore } from "firebase/firestore";

// Set Firebase auth persistence to LOCAL

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCrRcY6bATR4Jcq2Zn6ZaLB6SHpZLYNsW4",
  authDomain: "speech-path-group.firebaseapp.com",
  projectId: "speech-path-group",
  storageBucket: "speech-path-group.appspot.com",
  messagingSenderId: "948104656867",
  appId: "1:948104656867:web:b576c88c22bc5228d692d6",
  measurementId: "G-19TEQ06L1W",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const perf = getPerformance(firebaseApp);
export const app = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();



// export const db = initializeFirestore(firebaseApp, { experimentalAutoDetectLongPolling: true });
export const storage = firebaseApp.storage();
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);