import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Pagination, Container } from "react-bootstrap";
import { AltJobService } from "../services/AltJobs";
import { JobService } from "../services/Jobs";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import "./JobsDashboard.css";

// Spinner Component
const Spinner = () => (
  <div className="d-flex justify-content-center my-3">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

// ErrorMessage Component
const ErrorMessage = ({ message }) => (
  <div className="alert alert-danger" role="alert">
    {message}
  </div>
);

// Reusable JobCard component
const JobCard = ({ job, onClick }) => (
  <Col lg={4} xs={12} onClick={onClick} role="button" tabIndex={0} onKeyPress={onClick}>
    <div className="job-card my-2">
      <div className="job-img">
        <img src={job.imageUrl || "https://via.placeholder.com/150"} alt={job.title} />
      </div>
      <div className="job-card-body">
        <h3>{job.title}</h3>
        <p className="d-flex justify-content-between">
          <span>Applicants: {Object.values(job.applicants).length}</span>
          <span className={job.status === "active" ? "jb bg-warning" : "jb bg-success"}>
            {job.status}
          </span>
        </p>
      </div>
    </div>
  </Col>
);

// SkeletonLoader component to display while loading
const SkeletonJobCard = () => (
  <Col lg={4} xs={12}>
    <div className="job-card-skeleton my-2">
      <div className="job-img-skeleton" />
      <div className="job-card-body-skeleton">
        <div className="skeleton-title" />
        <div className="skeleton-line" />
        <div className="skeleton-line" />
      </div>
    </div>
  </Col>
);

// Reusable PaginationComponent
const PaginationComponent = ({ page, totalPages, onPageChange, loading }) => {
  if (totalPages < 1) return null; // Ensure valid pagination

  return (
    <div style = {{paddingTop:"20px"}}className="d-flex justify-content-center align-items-center">
      <Pagination className="my-2 align-items-center">
        <Pagination.First onClick={() => onPageChange(1)} disabled={page === 1 || loading} />
        <Pagination.Prev onClick={() => onPageChange(page - 1)} disabled={page === 1 || loading} />
        {[...Array(totalPages)].map((_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === page}
            onClick={() => onPageChange(i + 1)}
            disabled={loading}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages || loading || totalPages === 0}
        />
        <Pagination.Last
          onClick={() => onPageChange(totalPages)}
          disabled={page === totalPages || loading || totalPages === 0}
        />
      </Pagination>
    </div>
  );
};

// JobDashboard component
const JobDashboard = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.authReducer.auth);
  const isCompany = useSelector((state) => state.authReducer.isCompany);

  const [jobs, setJobs] = useState([]);
  const [altJobs, setAltJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingAltJobs, setLoadingAltJobs] = useState(false);
  const [jobError, setJobError] = useState(null); // State for job errors
  const [altJobError, setAltJobError] = useState(null); // State for alt job errors

  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    firstVisible: null,
    lastVisible: null,
  });
  const [altPagination, setAltPagination] = useState({
    page: 1,
    totalPages: 1,
    firstVisible: null,
    lastVisible: null,
  });

  const itemsPerPage = 6; // Number of items per page

  // Memoized fetchJobs function
  const fetchJobs = useCallback(async (userId, page, prev = false) => {
    try {
      setLoadingJobs(true);
      setJobError(null); // Reset error state before fetching
      let res;

      if (page === 1) {
        res = await JobService.getJobs(userId, itemsPerPage, null);
      } else if (prev && page > 1) {
        res = await JobService.getJobs(userId, itemsPerPage, pagination.firstVisible, true);
      } else {
        res = await JobService.getJobs(userId, itemsPerPage, pagination.lastVisible);
      }

      if (res.docs.length > 0) {
        const firstVisible = res.docs[0];
        const lastVisible = res.docs[res.docs.length - 1];
        const jobPromises = res.docs.map(async (i) => {
          const jobDetails = i.data();
          const applicants = await i.ref.collection("applicants").get();
          return {
            ...jobDetails,
            id: i.id,
            applicants: applicants.docs.map((x) => x.data()),
          };
        });

        const jobAry = await Promise.all(jobPromises);
        const totalJobs = await JobService.getTotalJobs(userId);
        const newTotalPages = Math.ceil(totalJobs / itemsPerPage);

        setJobs(jobAry);
        setPagination((prev) => ({
          ...prev,
          page,
          totalPages: newTotalPages,
          firstVisible,
          lastVisible,
        }));
      } else {
        setJobError("No jobs found.");
      }
    } catch (error) {
      setJobError("Unable to fetch job postings. Please try again later.");
    } finally {
      setLoadingJobs(false);
    }
  }, [itemsPerPage, pagination.firstVisible, pagination.lastVisible]);

  // Memoized fetchAltJobs function
  const fetchAltJobs = useCallback(async (userId, page, prev = false) => {
    try {
      setLoadingAltJobs(true);
      setAltJobError(null); // Reset error state before fetching
      let res;

      if (page === 1) {
        res = await AltJobService.getAllJobs(userId, itemsPerPage, null);
      } else if (prev && page > 1) {
        res = await AltJobService.getAllJobs(userId, itemsPerPage, altPagination.firstVisible, true);
      } else {
        res = await AltJobService.getAllJobs(userId, itemsPerPage, altPagination.lastVisible);
      }

      if (res.docs.length > 0) {
        const firstVisible = res.docs[0];
        const lastVisible = res.docs[res.docs.length - 1];
        const jobPromises = res.docs.map(async (i) => {
          const jobDetails = i.data();
          const applicants = await i.ref.collection("applicants").get();
          return {
            ...jobDetails,
            id: i.id,
            applicants: applicants.docs.map((x) => x.data()),
          };
        });

        const jobAry = await Promise.all(jobPromises);
        const totalAltJobs = await AltJobService.getTotalAltJobs(userId);
        const newTotalPages = Math.ceil(totalAltJobs / itemsPerPage);

        setAltJobs(jobAry);
        setAltPagination((prev) => ({
          ...prev,
          page,
          totalPages: newTotalPages,
          firstVisible,
          lastVisible,
        }));
      } else {
        setAltJobError("No alternate jobs found.");
      }
    } catch (error) {
      setAltJobError("Unable to fetch alternate job postings. Please try again later.");
    } finally {
      setLoadingAltJobs(false);
    }
  }, [itemsPerPage, altPagination.firstVisible, altPagination.lastVisible]);

  useEffect(() => {
    if (!isCompany) {
      navigate("/manage-jobs/your-applications");
    } else {
      fetchJobs(user.uid, pagination.page);
      fetchAltJobs(user.uid, altPagination.page);
    }
  }, [isCompany, user.uid, navigate]);

  const handlePageChange = (newPage) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPagination((prev) => ({ ...prev, page: newPage }));
      fetchJobs(user.uid, newPage, newPage < pagination.page);
    }
  };

  const handleAltPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= altPagination.totalPages) {
      setAltPagination((prev) => ({ ...prev, page: newPage }));
      fetchAltJobs(user.uid, newPage, newPage < altPagination.page);
    }
  };

  return (
    <>
      <Container fluid className="mb-5">
        <Row>
          <Col xs={6}>
            <h3>Your Jobs</h3>
          </Col>
          <Col xs={6} className="text-end">
            {isCompany && (
              <>
                <Button onClick={() => navigate("/manage-jobs/create")} className="me-2">
                  Create Job
                </Button>
                <Button onClick={() => navigate("/manage-jobs/create-alt")}>
                  Create Alternate Job
                </Button>
              </>
            )}
          </Col>
        </Row>

        <Row className="data-container">
          {loadingJobs ? (
            <Spinner />
          ) : jobError ? (
            <ErrorMessage message={jobError} />
          ) : (
            jobs.map((job) => (
              <JobCard
                key={job.id}
                job={job}
                onClick={() => navigate("/manage-jobs/status/" + job.id)}
              />
            ))
          )}
        </Row>
        {jobs.length > 0 && (
          <PaginationComponent
            page={pagination.page}
            totalPages={pagination.totalPages}
            onPageChange={handlePageChange}
            loading={loadingJobs}
          />
        )}
      </Container>

      <Container fluid>
        <Row>
          <h3>Your Alt Jobs</h3>
        </Row>
        <Row className="data-container">
          {loadingAltJobs ? (
            <Spinner />
          ) : altJobError ? (
            <ErrorMessage message={altJobError} />
          ) : (
            altJobs.map((job) => (
              <JobCard
                key={job.id}
                job={job}
                onClick={() => navigate("/manage-jobs/status-alt/" + job.id)}
              />
            ))
          )}
        </Row>
        {altJobs.length > 0 && (
          <PaginationComponent
            page={altPagination.page}
            totalPages={altPagination.totalPages}
            onPageChange={handleAltPageChange}
            loading={loadingAltJobs}
          />
        )}
      </Container>
    </>
  );
};

export default JobDashboard;
