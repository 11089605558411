import React from "react";
const DashCard = (props) => {
  return (
    <div className="dash-card" onClick={props?.onClick}>
      <div className="dash-icon">
        <img src={props.icon} />
      </div>
      <div className="dash-title">
        <h3>{props.title}</h3>
        <p>{props.subTitle}</p>
      </div>
    </div>
  );
};
export default DashCard;
