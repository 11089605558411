import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import notFoundImage from "../assets/illustrations/illustration_404.svg";
const NotFoundPage = () => {
  return (
    <Container className="not-found-container">
      <Row className="justify-content-center">
        <Col md={6} className="my-5 text-center">
          <h2>Sorry, page not found!</h2>
          <p>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <img src={notFoundImage} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
