import axios from "axios";

const facebookAccessToken1 =
  "EAAL8kZAz6GqMBO3nhNFG8z7RAgQCOAt1OpyzVwx6hg6lXi7XFpTQjpetb0Vddtv74yNxRV2OSj4JoGxZCefu68jSBCNRkeL9ifS3eLaZCYQ06eTp1kXyH7cGl8TdiRBqIdaw4Chf6XeO8W3B97oluSkp1ZAO0Nvc1OAoqdzRXVggSyep1hcqWIBVK6ngZA1psEUJFHfwFC01lRZBzS";
// const pageId3 = 117956915593364; // Replace with your actual page ID
const pageId3 = 295649573632841;
const postUrl = `https://graph.facebook.com/v19.0/${pageId3}/feed`;

export const postJobInGroup = (title, link) => {
  const payload = {
    link: link,
    message: title,
    access_token: facebookAccessToken1,
    
  };
  console.log(postJobInGroup, 'postjobFB services')
  console.log(link, 'link')
  
  return axios.post(postUrl, payload);
};


// export const postJobInGroup = (title, link, pic) => {
//   const payload = {
//     link: link,
//     message: title,
//     access_token: facebookAccessToken1,
//     picture: pic,
//   };
//   return axios.post(postUrl, payload);
// };
