import { db, storage } from "../firebase-config";


export class JobService {
  static createJob = async (data) => {
    const docRef = db.collection("jobs");
    return docRef.add({
      ...data,
      creationTime: new Date().toISOString(),
    });
  };
  static getJobDetails = async (userId) => {
    const docRef = db.collection("jobs").doc(userId);
    return docRef.get();
  };
  
  // static getJobs = async (id) => {
  //   const docRef = db.collection("jobs").where("recruiter", "==", id);
  //   return docRef.get();
  // };

  // static getJobs = async (id, limit = 5, index = null, prev = false) => {
  //   const docRef = index
  //     ? prev
  //       ? db
  //           .collection("jobs")
  //           .where("recruiter", "==", id)  // Filter by recruiter
  //           .limitToLast(limit)
  //           .orderBy("creationTime", "desc")
  //           .endBefore(index)
  //       : db
  //           .collection("jobs")
  //           .where("recruiter", "==", id)  // Filter by recruiter
  //           .limit(limit)
  //           .orderBy("creationTime", "desc")
  //           .startAfter(index)
  //     : db
  //         .collection("jobs")
  //         .where("recruiter", "==", id)  // Filter by recruiter
  //         .limit(limit)
  //         .orderBy("creationTime", "desc");
  
  //   return docRef.get();
  // };

  static getJobs = async (id, limit = 6, index = null, prev = false) => {
    let query = db
      .collection("jobs")
      .where("recruiter", "==", id)  // Filter by recruiter
      .orderBy("creationTime", "desc");
  
    // Apply pagination with the cursor (index)
    if (index) {
      query = prev
        ? query.endBefore(index).limitToLast(limit)
        : query.startAfter(index).limit(limit);
    } else {
      query = query.limit(limit);
    }
  
    return query.get();
  };

  // Code for the JobsDashboard
  static getTotalJobs = async (id) => {
    const query = db
      .collection("jobs")
      .where("recruiter", "==", id);
  
    const snapshot = await query.get();
    return snapshot.size; // Return total number of jobs
  };
  
  
  
  
  static getAllJobs = async (limit = 5, index = null, prev = false) => {
    const docRef = index
      ? prev
        ? db
            .collection("jobs")
            .limitToLast(limit)
            .orderBy("creationTime", "desc")
            .endBefore(index)
        : db
            .collection("jobs")
            .limit(limit)
            .orderBy("creationTime", "desc")
            .startAfter(index)
      : db.collection("jobs").limit(limit).orderBy("creationTime", "desc");
    return docRef.get();
  };
  static applyJob = async (jobId, userId) => {
    const applicantRef = db
      .collection("jobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return applicantRef.set({
      id: userId,
      selected: false,
    });
  };
  static checkApplication = async (jobId, userId) => {
    const applicantRef = db
      .collection("jobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return applicantRef.get();
  };
  static getApplicants = async (jobId) => {
    const applicants = db
      .collection("jobs")
      .doc(jobId)
      .collection("applicants");
    return applicants.get();
  };
  


  static getAppliedJobs = async (userId) => {
    const applicantRef = db
      .collectionGroup("applicants")
      .where("id", "==", userId);
  
    return applicantRef.get().then(async (querySnapshot) => {
      const jobs = [];
  
      for (const doc of querySnapshot.docs) {
        const jobRef = doc.ref.parent.parent; // Get the parent job document reference
        const jobDoc = await jobRef.get(); // Await the job data
  
        if (jobDoc.exists) {
          jobs.push(jobDoc.data()); // Push the job data to the array
        }
      }
  
      return jobs; // Return the array of job data
    });
  };
  

  static selectSeeker = async (jobId, userId) => {
    const docRef = db
      .collection("jobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return docRef.update({
      selected: true,
    });
  };
  static closeJob = async (jobId) => {
    const docRef = db.collection("jobs").doc(jobId);
    return docRef.update({
      status: "closed",
    });
  };
  static storeJobsImage = (image, id) => {
    return storage
      .ref(`/jobs`)
      .child(id)
      .putString(image.split("base64,")[1], "base64", {
        contentType: "image/jpg",
      });
  };
}