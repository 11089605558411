import {db,  storage } from "../firebase-config";



export class AltJobService {
  static createJob = async (data) => {
    const docRef = db.collection("altjobs");
    return docRef.add({
      ...data,
      creationTime: new Date().toISOString(),
    });
  };
  static getJobDetails = async (id) => {
    const docRef = db.collection("altjobs").doc(id);
    return docRef.get();
  };
  // static getAllJobs = async (id) => {
  //   const docRef = db.collection("altjobs").where("recruiter", "==", id);
    
  //   return docRef.get();
    
  // };

  // static getAllJobs = async (id, limit = 5, index = null, prev = false) => {
  //   const docRef = index
  //     ? prev
  //       ? db
  //           .collection("altjobs")
  //           .where("recruiter", "==", id)  // Filter by recruiter
  //           .limitToLast(limit)
  //           .orderBy("creationTime", "desc")
  //           .endBefore(index)
  //       : db
  //           .collection("altjobs")
  //           .where("recruiter", "==", id)  // Filter by recruiter
  //           .limit(limit)
  //           .orderBy("creationTime", "desc")
  //           .startAfter(index)
  //     : db
  //         .collection("altjobs")
  //         .where("recruiter", "==", id)  // Filter by recruiter
  //         .limit(limit)
  //         .orderBy("creationTime", "desc");
  
  //   return docRef.get();
  // };

  static getAllJobs = async (id, limit = 6, index = null, prev = false) => {
    let query = db
      .collection("altjobs")
      .where("recruiter", "==", id)
      .orderBy("creationTime", "desc");
  
    if (index) {
      query = prev
        ? query.endBefore(index).limitToLast(limit)
        : query.startAfter(index).limit(limit);
    } else {
      query = query.limit(limit);
    }
  
    return query.get();
  };
  
  static getTotalAltJobs = async (id) => {
    const query = db
      .collection("altjobs")
      .where("recruiter", "==", id);  // Filter by recruiter
  
    const snapshot = await query.get();
    return snapshot.size;  // Return total number of alternate jobs
  };
  
  
  
  static getAltJobs = async (limit = 5, index = null, prev = false) => {
    const docRef = index
      ? prev
        ? db
            .collection("altjobs")
            .limitToLast(limit)
            .orderBy("creationTime", "desc")
            .endBefore(index)
        : db
            .collection("altjobs")
            .limit(limit)
            .orderBy("creationTime", "desc")
            .startAfter(index)
      : db.collection("altjobs").limit(limit).orderBy("creationTime", "desc");
    return docRef.get();
  };
  static applyJob = async (jobId, userId) => {
    const applicantRef = db
      .collection("altjobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return applicantRef.set({
      id: userId,
      selected: false,
    });
  };
  static checkApplication = async (jobId, userId) => {
    const applicantRef = db
      .collection("altjobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return applicantRef.get();
  };
  static getApplicants = async (jobId) => {
    const applicants = db
      .collection("altjobs")
      .doc(jobId)
      .collection("applicants");
    return applicants.get();
  };

  static getAppliedJobs = async (userId) => {
    try {
      // Query the applicants subcollection in altjobs where the user has applied
      const applicantRef = db.collectionGroup("applicants")
        .where("id", "==", userId)  // Match user by their UID

      // Fetch all the applied alt jobs for the user
      const querySnapshot = await applicantRef.get();

      const jobs = [];

      for (const doc of querySnapshot.docs) {
        const jobRef = doc.ref.parent.parent; // Get the alt job document reference
        const jobDoc = await jobRef.get(); // Fetch the alt job data
        
        if (jobDoc.exists) {
          jobs.push({
            id: jobDoc.id, // Add job ID
            ...jobDoc.data(), // Spread the job data
          });
        }
      }

      console.log(`Found ${jobs.length} alt jobs for user ${userId}`);
      return jobs;

    } catch (error) {
      console.error("Error fetching applied alt jobs:", error);
      return [];
    }
  };
  
  static selectSeeker = async (jobId, userId) => {
    const docRef = db
      .collection("altjobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return docRef.update({
      selected: true,
    });
  };
  static closeJob = async (jobId) => {
    const docRef = db.collection("altjobs").doc(jobId);
    return docRef.update({
      status: "closed",
    });
  };
  static storeJobsImage = (image, id) => {
    return storage
      .ref(`/jobs`)
      .child(id)
      .putString(image.split("base64,")[1], "base64", {
        contentType: "image/jpg",
      });
  };
}
