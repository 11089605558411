import { db, storage } from "../firebase-config";

export class ProfileService {
  static createProfile = (id, name, email, phone, avatar) => {
    const docRef = db.collection("profile").doc(id);
    return docRef.set({
      id: id,
      name: name,
      email: email,
      phone: phone,
      avatar: avatar,
    });
  };
  static getProfileDetails = async (id) => {
    const docRef = db.collection("profile").doc(id);
    return docRef.get();
    
  };
  static updateProfileDetails = async (id, data) => {
    const docRef = db.collection("profile").doc(id);
    return docRef.update({
      id: id,
      name: data.name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      bio: data.bio,
      place: data.place,
      dob: data.dob,
      fb: data.fb,
      insta: data.insta,
      blog: data.blog,
      yt: data.yt,
      twitter: data.twitter,
      linkedin: data.linkedin,
      role: data.role,
      active: data.active,
    });
  };
  static getProfiles = (page = 1, limit = 10) => {
    const docRef = db.collection("profile");
    return docRef.get();
  };
  static getProfilesName = (name) => {
    const docRef = db.collection("profile").where("name", "<=", name);
    return docRef.get();
  };
  static storeProfileImage = (image, id, type) => {
    return storage
      .ref(`/profile/${type}`)
      .child(id)
      .putString(image, "base64", { contentType: "image/jpg" });
  };
}