import React, { useState } from "react";
import "./App.css";
import "@fortawesome/fontawesome-free/js/all.js";
import AppRouter from "./navigation";
import store, { peristor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";



function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={peristor}>
        <AppRouter />
      </PersistGate>{" "}
    </Provider>
  );
}

export default App;
